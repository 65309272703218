// --------------------------------------------------------------------------
// Modules / Box
// --------------------------------------------------------------------------

.box {}

.box--padded {
  @include ms-padding(2);
}

.box--bordered {
  border: 1px solid $color-grey-dark;
}
