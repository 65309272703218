
// TABLE OF CONTENTS
// -----------------
// 01. News
// 02. Latest News
// 03. Pagination Styles

// --------------------------------------------------------------------------
// 01. News
// --------------------------------------------------------------------------

.module-news {
}

.module-news .news__item {
  @include ms-padding-top(1);
  @include ms-margin-top(1);
  border-top:1px solid $color-grey-light;

  &:first-child {
    border-top:none;
  }
}

.module-news .item__date span,
.module-news .item__title span,
.module-news .item__description span,
.module-news .item__link span {
  color: $color-text-alpha;
  &:hover {
    color: $color-text-alpha;
  }
}

.module-news .item__date {
  @include ms-font-size(-1);
}

.module-news .item__title {
  margin-bottom: 10px;
}

.module-news .item__link {
  @include font-weight-body(bold);
  text-decoration: none;

}

.module-news .item__link span {
  &:after {
    position: relative;
    padding-left: 10px;
  }
  &:hover {
    color: rgba($color-text-alpha, .75);
  }
}

// --------------------------------------------------------------------------
// 02. Latest News
// --------------------------------------------------------------------------

.module-latest-news {
  background-color: $color-alpha;
}

.module-latest-news .news__news {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.module-latest-news .news__seperator {
  height: 1px;
  width: 100%;
  background-color: rgba($white, .15);
  @include ms-margin(0 zero, break-0 to break-1);
  @include ms-margin(zero, break-2 to break-4);

  @include breakpoint(break-2) {
    width: 1px;
    height: auto;
  }
}

.module-latest-news .news__item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @include ms-padding-left(1, break-2 to break-4);

  &:first-child {
    @include ms-padding-left(zero);
  }
}

.module-latest-news .news__news.news__news--2 .news__item {
  @include breakpoint(break-2) {
    width: span(6);
  }
}

.module-latest-news .news__news.news__news--3 .news__item {
  @include breakpoint(break-2) {
    width: span(4);
  }
}

.module-latest-news .item__date span,
.module-latest-news .item__title span,
.module-latest-news .item__description span,
.module-latest-news .item__link span {
  color: $white;
  &:hover {
    color: $white;
  }
}

.module-latest-news .item__date {
  @include ms-font-size(-1);
}

.module-latest-news .item__title {
  margin-bottom: 10px;
}

.module-latest-news .item__link {
  @include font-weight-body(bold);
  @include ms-padding-top(1);
}

.module-latest-news .item__link span {
  &:after {
    position: relative;
    @include ms-padding-left(2);
    margin-top:5px;
  }
  &:hover {
    color: rgba($white, .75);
  }
}

// --------------------------------------------------------------------------
// 03. Pagination Styles
// --------------------------------------------------------------------------

.module-paginator {
  @include ms-margin-top(2);
}
.f3-widget-paginator {
  display: flex;
  justify-content: space-between;
}

.f3-widget-paginator li.current {
  @include ms-padding(-1 1);
  background-color: $color-alpha;
  display: inline-block;
  border-radius: $border-radius;
  color: $white;
  @include ms-font-size(0);
  @include font-weight-body(bold);
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
}

.f3-widget-paginator li {
  display: inline-block;
  @include ms-font-size(0);
  @include font-weight-body(bold);

  @include ms-margin-right(0);
  border-radius: $border-radius;
  border:2px solid $color-alpha;

  & a {
    display: block;
    color: $color-text-alpha;
    @include ms-padding(-1 1);
    text-decoration: none;
  }

  &:hover {
    box-shadow: none;
  }
}
