////
/// Font Features
/// @group font
/// @author pd
////


/// Common ligatures, discretionary ligatures,
/// lining numerals and kerning with OpenType
///
/// @example scss - Add the open-type features
///   @include open-type;
@mixin open-type {
  @if $feature-enable-ot-features {
    -webkit-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
    -moz-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
    -ms-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
    -o-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
    font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
  }
}

/// Real small caps
///
/// @example scss - Add the small caps
///   @include small-caps;
@mixin small-caps {
  letter-spacing: 0.1em;
  @if $feature-enable-caps {
    -webkit-font-feature-settings: 'smcp' 1, 'kern' 1;
    -moz-font-feature-settings: 'smcp' 1, 'kern' 1;
    -ms-font-feature-settings: 'smcp' 1, 'kern' 1;
    -o-font-feature-settings: 'smcp' 1, 'kern' 1;
    font-feature-settings: 'smcp' 1, 'kern' 1;
  }
}

/// Consistent height numbers with OpenType
///
/// @example scss - Add the consistent height numbers
///   @include lining-numerals;
@mixin lining-numerals {
  @if $feature-enable-numerals {
    -webkit-font-feature-settings: 'lnum' 1, 'kern' 1;
    -moz-font-feature-settings: 'lnum' 1, 'kern' 1;
    -ms-font-feature-settings: 'lnum' 1, 'kern' 1;
    -o-font-feature-settings: 'lnum' 1, 'kern' 1;
    font-feature-settings: 'lnum' 1, 'kern' 1;
  }
}


/// Ascending and descending numbers with OpenType
///
/// @example scss - Add the ascending and descending numbers
///   @include oldstyle-numerals;
@mixin oldstyle-numerals {
  @if $feature-enable-oldstyle {
    -webkit-font-feature-settings: 'onum' 1, 'kern' 1;
    -moz-font-feature-settings: 'onum' 1, 'kern' 1;
    -ms-font-feature-settings: 'onum' 1, 'kern' 1;
    -o-font-feature-settings: 'onum' 1, 'kern' 1;
    font-feature-settings: 'onum' 1, 'kern' 1;
  }
}

/// Renders the font-family key from `$map`
///
/// @access private
///
/// @param {Map} $map - Map that includes the font-family as key
///
/// @example scss - Render font-family from given map
///   $georgia: Georgia, Cambria, "Times New Roman", Times, serif;
///   $map: (font-family: $georgia);
///   @include font-family($map);
@mixin font-family($map) {
  $key: "font-family";
  @if map-has-key($map, $key) {
    font-family: map-get($map, $key);
  }@else {
    @warn "font-family not found in the #{$map}.";
  }
}

/// Output font family for config body font map `$type-body`
///
/// @example scss - Render font-family from `$type-body`
///   @include font-family-body;
@mixin font-family-body {
  @include font-family($type-body);
}

/// Output font family for config body font map `$type-heading`
///
/// @example scss - Render font-family from `$type-heading`
///   @include font-family-heading;
@mixin font-family-heading {
  @include font-family($type-heading);
}

/// Output font family for monospaced font map `$type-monospace`
///
/// @example scss - Render font-family from `$type-monospace`
///   @include font-family-monospace;
@mixin font-family-monospace {
  @include font-family($type-monospace);
}

/// Renders the font-family key from `$map`
///
/// @access private
///
/// @param {Map} $map - `$map` that includes the font-weight as key
/// @param {String} $key - font-weight `$key`
///
/// @example scss - Render CSS font-weight from `$map` for `$key`
///   $map: (
///     light: 300,
///     regular: 400,
///     medium: 500,
///     bold: 700,
///     italic: italic
///   );
///   @include font-weight($map, light);
@mixin font-weight($map, $key) {
  @if map-has-key($map, $key) {
    font-weight: map-get($map, $key);
  }@else {
    @warn "Key not found in the #{$map}.";
  }
}

/// Output one font weight from `$type-body` font map
///
/// @param {String} $font-weight-type - Font weight type (from `$type-body`)
///
/// @example scss - Render CSS font-weight-type _light_ from `$type-body`
///   @include font-weight-body(light);
@mixin font-weight-body($font-weight-type) {
  @include font-weight($type-body, $font-weight-type);
}

/// Output one font weight from `$type-heading` font map
///
/// @param {String} $font-weight-type - Font weight type (from `$type-heading`)
///
/// @example scss - Render CSS font-weight-type _light_ from `$type-heading`
///   @include font-weight-heading(light);
@mixin font-weight-heading($font-weight-type) {
  @include font-weight($type-heading, $font-weight-type);
}

/// Output one font weight from `$type-monospace` font map
///
/// @param {String} $font-weight-type - Font weight type (from `$type-monospace`)
///
/// @example scss - Render CSS font-weight-type _light_ from `$type-monospace`
///   @include font-weight-heading(light);
@mixin font-weight-monospace($value) {
  @include font-weight($type-monospace, $value);
}

/// Root font-size in em, outputted in correct breakpoints
///
@mixin rootsize {
  font-size: nth($sizes, 1) / 16 * 1em;
  // Loop through breakpoints
  @if $breakpoints-limit > 1 {
    @for $i from 2 through $breakpoints-limit {
      $bp-value: nth($points, $i);
      @include breakpoint($bp-value) {
        font-size: nth($sizes, $i) / 16 * 1em;
      }
    }
  }
}

/// Adds Icomoon `$icon`
/// @param {String} $icon - Icon
/// @example scss - Adding an icon
///   @include icomoon("\e602");
@mixin icomoon($icon) {
  content: $icon;
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
