// --------------------------------------------------------------------------
// Modules / Prev/Next Nav
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.content-item.module-prev-next-nav {
  display: flex;

  & .prev__link,
  & .next__link {
    position: relative;
    width: 50%;
    overflow: hidden;
    cursor: pointer;

  }

  & .next__link {
    box-shadow: -1px 0 0 0 rgba($white, 1);
  }
}

.module-prev-next-nav .link__background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.module-prev-next-nav .link__box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @include ms-padding(5 0);
  color: $white;
  @include font-family-body;
  @include font-weight-body(bold);
  @include ms-font-size(2);
  z-index: 1;
}
