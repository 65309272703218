// --------------------------------------------------------------------------
// Modules / lane Box
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles
// 02. Filter Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-lane-box {
  display: block;
  color: $color-text-alpha;
  background-color: $white;
  box-shadow: $shadow-alpha;
  transition: opacity .25s $easing-alpha-out, border-radius .25s $easing-alpha-out, filter .25s $easing-alpha-out, transform .25s $easing-alpha-out, box-shadow .25s $easing-alpha-out;
  max-width:400px;
  margin: 0 auto;
  @include breakpoint(break-2) {
    margin:0;
  }

  &:visited {
    color: $color-text-alpha;
  }

  &:hover,
  &:active,
  &:focus {

  }
}

.module-lane-box .lane-box__holder {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.module-lane-box .lane-box__content {
  @include ms-margin-left(3);
  @include ms-padding(0 zero);
  width:50%;
}

.module-lane-box .content__headline {
  @include font-weight-body(bold);
  @include ms-padding(0 3);
  color: $color-alpha;
  text-align: center;

  & span {
    @include ms-font-size(1);
    @include font-weight-body(bold);

  }
}
.module-lane-box .content__values {
  & span {
    display:block;
    padding-top:5px;
    position: relative;
    padding-left:40px;

    &:before {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      border:1px solid $color-grey-light;
      position:absolute;
      top:9px;
      left:0;
    }
  }
  & span.blue {
    &:before {
      background-color:blue;
    }
  }
  & span.yellow {
    &:before {
      background-color:yellow;
    }
  }
  & span.red {
    &:before {
      background-color:red;
    }
  }
  & span.-no-border {
    &:before {
      border:1px solid white;
    }
  }
}

.module-lane-box .lane-box__image {
  width:50%;
  @include ms-margin-right(2);
  @include ms-padding(0 zero);
}

.module-lane-box .lane-box__image img {
}
