// --------------------------------------------------------------------------
// Modules / Hero
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-hero {
  @include ms-padding($spacing-epsilon zero);
  background-color: $color-grey-light;
  @include breakpoint(break-1) {
    padding:0;
    background-color: transparent;
  }
}
.module-hero.-hide-mobile {
  display:none;
  @include breakpoint(break-2) {
    display:block;
  }
}

.module-hero .hero__item {
  position: relative;
}

.module-hero .item__image {
  position: relative;
  z-index: 5;
  display:none;
  @include breakpoint(break-1) {
    display:block;
  }
}

.module-hero .item__content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 10;
  text-align: center;
  @include breakpoint(break-1) {
    text-align: left;
  }

  @include breakpoint(break-1) {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.module-hero .content__wrapper {
  width: 100%;
}

.module-hero .content__headline {

  & h1 {
    color: $color-text-alpha;
    @include ms-font-size(2, break-0);
    @include ms-font-size(4, break-1);
    @include ms-font-size(5, break-2 to break-4);
    margin-bottom: 0;
    @include breakpoint(break-1) {
      color: $white;
    }
  }

  & span {
    display: block;
    color: $color-text-alpha;
    @include breakpoint(break-1) {
      color: $white;
      display:none;
    }
    @include breakpoint(break-2) {
      display:block;
    }
  }
}

.module-hero .content__button {
  @include ms-padding-top(1);
}
