////
/// Config - global variables, colour palettes, etc.
///
/// @group config
/// @author dm
////


// TABLE OF CONTENTS
// -----------------
// 01. Fonts
// 02. Type Setup
// 03. Breakpoints
// 04. Modular Scale
// 05. Features
// 06. Various
// 07. Colors
// 08. Easings – a set of cool easings
// 09. Various


// --------------------------------------------------------------------------
// 01. Fonts - families and variables
// --------------------------------------------------------------------------

// Basic CSS font stacks
$georgia: Georgia, Cambria, "Times New Roman", Times, serif;
$helvetica: "Helvetica Neue", Helvetica, Arial, sans-serif;
$lucida-grande: "Lucida Grande", Tahoma, Verdana, Arial, sans-serif;
$monospace: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
$verdana: Verdana, Geneva, sans-serif;

// Typefaces served by externals (Google, Typekit, MyFonts, ...)
// NOTE: Depends on the included stylesheet
$lato: "Lato";
$robotoslab: "Roboto Slab";

// --------------------------------------------------------------------------
// 02. Type Setup
// --------------------------------------------------------------------------

/// Baseline height
///
/// @type Number
$line-height: 1.6;

/// Body type settings
///
/// @type Map
$type-body: (
  font-family: ($lato),
  light: 300,
  regular: 400,
  medium: 400,
  bold: 900,
  italic: italic
);

/// Headline type settings
///
/// @type Map
$type-heading: (
  font-family: ($robotoslab),
  light: 300,
  regular: 400,
  medium: 700,
  bold: 700
);

/// Monospace type settings
///
/// @type Map
$type-monospace: (
  font-family: $monospace,
  regular: 400
);


// --------------------------------------------------------------------------
// 03. Breakpoints – breakpoint related variables
// --------------------------------------------------------------------------

/// Default `media-type` used by the `breakpoint` mixin
///
/// Use `screen` or `false` to disable the media-type
///
/// @type String | Bool
/// @see {mixin} breakpoint
/// @link http://www.w3.org/TR/CSS2/media.html#media-types
$breakpoints-default-media-type: false;

/// Major breakpoints
///
/// @type Map
$breakpoints: (
  break-0: 0,
  break-1: 640,
  break-2: 1024,
  break-3: 1440,
  break-4: 1680
);

/// Separate breakpoint for navigation
///
/// @type Map
$breakpoints-nav: (
  break-0: 650
);

/// Root font-sizes for each breakpoint.
///
/// **NOTE:** Make sure to have as many sizes as `$breakpoints` above.
///
/// @type Map
/// @see $breakpoints
$rootsizes: (
  rootsize-0: 18,
  rootsize-1: 18,
  rootsize-2: 18,
  rootsize-3: 18,
  rootsize-4: 18
);

/// Control the line-length with max-width values for the container.
/// Aim for 75–100 characters a line when possible, at smaller sizes
/// type size is more important.
///
/// **NOTE:** Make sure to have as many widths as breakpoints exist in the
/// `$breakpoints` map.
///
/// @type Map
/// @see $breakpoints
$maxwidths: (
  maxwidth-0: 0.86,
  maxwidth-1: 0.90,
  maxwidth-2: 0.86,
  maxwidth-3: 0.82,
  maxwidth-4: 1280px
);


// --------------------------------------------------------------------------
// 04. Modular Scale – for more meaningful typography
// --------------------------------------------------------------------------

/// Modular scale base.
///
/// Overwrites modular-scale default variables.
///
/// Please see http://github.com/at-import/modular-scale
///
/// @type String
/// @link http://github.com/at-import/modular-scale
$ms-base: 1em;

/// Modular scale ratio.
///
/// Value must be one of the modular scale variables.
///
/// Please see http://github.com/at-import/modular-scale
///
/// @type String
/// @link http://github.com/at-import/modular-scale
$ms-ratio: $major-third;

/// Setting responsive modular-scales.
/// Please see http://github.com/at-import/modular-scale
/// **NOTE:** Use appropriate scales for viewport sizes.
/// **NOTE:** Make sure to have as many scales as `$breakpoints` above.
///
/// @type Map
/// @see $breakpoints
/// @link http://github.com/at-import/modular-scale
$modular-scale: (
  scale-0: $major-third,
  scale-1: $major-third,
  scale-2: $major-third,
  scale-3: $major-third,
  scale-4: $major-third
);

/// EM base value used in bourbon em calculations
///
/// **Don't drop the variable since it is needed by
/// bourbon calculations!**
///
/// @link http://bourbon.io/docs/#em-base
$em-base: 16px;

// --------------------------------------------------------------------------
// 05. Features
// --------------------------------------------------------------------------

/// **Feature:** Toggle common ligatures, discretionary ligatures,
/// lining numerals and kerning with OpenType
///
/// @type Bool
$feature-enable-ot-features: true;

/// **Feature:** Toggle real small caps
///
/// @type Bool
$feature-enable-caps: true;

/// **Feature:** Toggle consistent height numbers with OpenType
///
/// @type Bool
$feature-enable-numerals: false;

/// **Feature:** Toggle ascending and descending numbers with OpenType
///
/// @type Bool
$feature-enable-oldstyle: false;

/// **Feature:** Toggle antialiased font rendering
///
/// @type Bool
$feature-enable-font-smoothing: true;

/// **Feature:** Toggle debugging
///
/// @type Bool
$feature-enable-debugging: false;


// --------------------------------------------------------------------------
// 06. Various
// --------------------------------------------------------------------------

/// Global modular scale value: _alpha_
///
/// @type Number
$spacing-alpha: -2;

/// Globale modular scale value: _beta_
///
/// @type Number
$spacing-beta: 0;

/// Global modular scale value: _gamma_
///
/// @type Number
$spacing-gamma: 4;

/// Global modular scale value: _delta_
///
/// @type Number
$spacing-delta: 6;

/// Global modular scale value: _epsilon_
///
/// @type Number
$spacing-epsilon: 8 ;

/// Global modular scale value: _zeta_
///
/// @type Number
$spacing-zeta: 8;

/// Global modular scale value for image headings
///
/// **NOTE:** 0 = initial value on scale (1em)
///
/// @type Number
$spacing-img-heading: -1;

/// Global modular scale value for paragraph headings
///
/// **NOTE:** 0 = initial value on scale (1em)
///
/// @type Number
$spacing-heading-paragraph: 0;

/// Global modular scale value list of headings
///
/// **NOTE:** 0 = initial value on scale (1em)
///
/// @type Number
$spacing-heading-list: -3;

/// Global spacing value for the "vertical gutter"
/// used to keep the bottom margin for _column-wrapper_,
/// _content-item_ and _col_ in sync.
///
/// @type Number
$spacing-vertical-gutter: $spacing-gamma;

/// Global border radius
///
/// @type String
$border-radius: 5px;

// --------------------------------------------------------------------------
// 07. Colors – a set of predefined colors ready to use or customize
// --------------------------------------------------------------------------

/// Color white
///
/// @type Color
$white: rgb(255, 255, 255);

/// Color black
///
/// @type Color
$black: rgb(26, 26, 26);

/// Main color alpha
///
/// @type Color
$color-alpha: rgb(14, 122, 55);

/// Color: Light grey
///
/// @type Color
$color-grey-light: rgb(238, 238, 238);

/// Color: Medium grey
///
/// @type Color
/// @requires $color-grey-light
$color-grey-medium: rgb(207, 207, 207);

/// Color: Dark grey
///
/// @type Color
/// @requires $color-grey-medium
$color-grey-dark: rgb(95, 100, 106);

/// Color text alpha
///
/// @type Color
$color-text-alpha: rgb(34, 34, 34);

/// Color text beta
///
/// @type Color
/// @requires $color-alpha
$color-text-beta: $color-alpha;

/// Color Border
///
/// @type Color
/// @requires $color-alpha
$color-border: $color-alpha;

/// Color Border Opaque
///
/// @type Color
/// @requires $color-alpha
$color-border-opaque: $color-alpha;

/// Color Box Shadow
///
/// @type Color
/// @requires $color-alpha
$color-box-shadow: $color-alpha;

// --------------------------------------------------------------------------
// 08. Easings – a set of cool easings
// --------------------------------------------------------------------------
/// Easing alpha
///
/// @type Easing
$easing-alpha-in: cubic-bezier(0.55, 0.055, 0.675, 0.19);   // easeInCubic
$easing-alpha-out: cubic-bezier(0.215, 0.61, 0.355, 1);     // easeOutCubic
$easing-alpha-in-out: cubic-bezier(0.645, 0.045, 0.355, 1); // easeInOutCubic

// --------------------------------------------------------------------------
// 09. Various
// --------------------------------------------------------------------------

// Shadows
$shadow-alpha: 0px 5px 20px 0 rgba(189, 189, 189, .5);
$text-shadow-alpha: 2px 2px 0 rgba($black, .2);
$text-shadow-beta: 1px 1px 0 rgba($black, .1);
