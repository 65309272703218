.taggd {
  position: relative;
}

.taggd__image {
  display: block;
  height: auto;
  width: 100%;
  margin: 0;
}

.taggd__wrapper {
  display: block;
  height: 1em;
  width: 1em;
  position: absolute;
}

.taggd__button_bak {
  display: block;
  height: 100%;
  width: 100%;
  padding: 20px;
  border: 10px;
  border-radius: 100px;
  margin: 0;
  background-color: rgba($color-alpha, 0.75);
  background-image: none;
  text-align: left;
}

.taggd__button {
  display: block;
  border: 3px solid $color-alpha;
  background-color: rgba($color-alpha, .75);
  -webkit-border-radius: 30px;
  height: 100%;
  width: 100%;
  -webkit-animation: pulsate 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0.0
}
@-webkit-keyframes pulsate {
  0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
  50% {opacity: 1.0;}
  100% {-webkit-transform: scale(1.5, 1.5); opacity: 0.0;}
}

.taggd__popup {
  position: absolute;
  left: 0;
  background-color: $white;
}
