// --------------------------------------------------------------------------
// Modules / Column (Grid - with the help of Susy (http://susy.oddbird.net/))
// --------------------------------------------------------------------------

// --------------------------------------------------------------------------
// 01. Variables (defining the grid)
// --------------------------------------------------------------------------

$grid: (columns: 12, column-width: false, gutters: 1 / 1.618, gutter-position: after, math: fluid);

// Sets global grid
@include layout($grid);

// --------------------------------------------------------------------------
// 02. Base column class
// --------------------------------------------------------------------------

.col {
  @if $feature-enable-debugging {
    border: 2px solid cyan;
  }
  @include ms-margin-bottom($spacing-vertical-gutter, break-0);
  @include ms-margin-bottom(zero, break-1);
}

.col:last-child {
  margin-bottom: 0;
}

// --------------------------------------------------------------------------
// 03. Breakpoint 0 (break-0) columns
// --------------------------------------------------------------------------

.col__b0-full {
  @include span(full);
}

.col__b0-3 {
  @include span(3);
}

.col__b0-3--last {
  @include span(3 last);
}

.col__b0-4 {
  @include span(4);
}

.col__b0-4--last {
  @include span(4 last);
}

.col__b0-6 {
  @include span(6);
}

.col__b0-6--last {
  @include span(6 last);
}

.col__b0-8 {
  @include span(8);
}

.col__b0-8--last {
  @include span(8 last);
}

.col__b0-9 {
  @include span(9);
}

.col__b0-9--last {
  @include span(9 last);
}


// --------------------------------------------------------------------------
// 04. Breakpoint 1 (break-1) columns
// --------------------------------------------------------------------------

@include breakpoint(break-1) {
  .col__b1-3 {
    @include span(3);
  }
  .col__b1-3--last {
    @include span(3 last);
  }
  .col__b1-4 {
    @include span(4);
  }
  .col__b1-4--last {
    @include span(4 last);
  }
  .col__b1-6 {
    @include span(6);
  }
  .col__b1-6--last {
    @include span(6 last);
  }
  .col__b1-8 {
    @include span(8);
  }
  .col__b1-8--last {
    @include span(8 last);
  }
  .col__b1-9 {
    @include span(9);
  }
  .col__b1-9--last {
    @include span(9 last);
  }
}

// --------------------------------------------------------------------------
// 05. Breakpoint 2 (break-2) columns
// --------------------------------------------------------------------------

@include breakpoint(break-2) {
  .col__b2-3 {
    @include span(3);
  }
  .col__b2-3--last {
    @include span(3 last);
  }
  .col__b2-4 {
    @include span(4);
  }
  .col__b2-4--last {
    @include span(4 last);
  }
  .col__b2-6 {
    @include span(6);
  }
  .col__b2-6--last {
    @include span(6 last);
  }
  .col__b2-7 {
    @include span(7);
    margin-right: span(1) + gutter();
  }
  .col__b2-8 {
    @include span(8);
  }
  .col__b2-8--last {
    @include span(8 last);
  }
  .col__b2-9 {
    @include span(9);
  }
  .col__b2-9--last {
    @include span(9 last);
  }
}



// --------------------------------------------------------------------------
// 06. Breakpoint 3 (break-3) columns
// --------------------------------------------------------------------------

@include breakpoint(break-3) {
  .col__b3-3 {
    @include span(3);
  }
  .col__b3-3--last {
    @include span(3 last);
  }
  .col__b3-4 {
    @include span(4);
  }
  .col__b3-4--last {
    @include span(4 last);
  }
  .col__b3-6 {
    @include span(6);
  }
  .col__b3-6--last {
    @include span(6 last);
  }
  .col__b3-8 {
    @include span(8);
  }
  .col__b3-8--last {
    @include span(8 last);
  }
  .col__b3-9 {
    @include span(9);
  }
  .col__b3-9--last {
    @include span(9 last);
  }
}

// --------------------------------------------------------------------------
// 07. Breakpoint 4 (break-4) columns
// --------------------------------------------------------------------------

@include breakpoint(break-4) {
  .col__b4-3 {
    @include span(3);
  }
  .col__b4-3--last {
    @include span(3 last);
  }
  .col__b4-4 {
    @include span(4);
  }
  .col__b4-4--last {
    @include span(4 last);
  }
  .col__b4-6 {
    @include span(6);
  }
  .col__b4-6--last {
    @include span(6 last);
  }
  .col__b4-8 {
    @include span(8);
  }
  .col__b4-8--last {
    @include span(8 last);
  }
  .col__b4-9 {
    @include span(9);
  }
  .col__b4-9--last {
    @include span(9 last);
  }
}

// --------------------------------------------------------------------------
// 08. Pushes - uncomment all needed pushing classes
// --------------------------------------------------------------------------

// NOTE: Pushes have to be declared after 03. Columns.
// NOTE: Make sure you reach all columns in each row, depending on the
// number of columns in your grid; e.g: push 0.5, span 11.5 (12 cols)

// .col__push--half {
//   @include push(0.5);
// }


// --------------------------------------------------------------------------
// 09. Special Columns - place various classes here
// --------------------------------------------------------------------------
