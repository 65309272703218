// --------------------------------------------------------------------------
// Modules / Contact Card
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-contact-card {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-color: $white;
  box-shadow: $shadow-alpha;
  z-index: 15;
  transition: opacity .35s $easing-alpha-out, transform .35s $easing-alpha-out;

  @include breakpoint(break-2) {
    max-width: none;
    margin-left: 0;
    margin-right: 0;
  }

  &.-fixed {
    position: fixed;
    max-height: 80%;
    top: 15%;
    margin-top: 0 !important;
    overflow-y: auto;
  }

  &.-hidden {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-40px);
  }
}

.module-contact-card .card__headline {
  @include ms-padding(2);
}

.module-contact-card #map {
  width: 100%;
  height: 25vh;
  transition: height .35s $easing-alpha-out;
}

.module-contact-card.-accordion-open #map {
  height: 15vh;
}

.module-contact-card .card__contact,
.module-contact-card .card__times {
  @include ms-padding(2);
  border-bottom: 1px solid $color-grey-medium;

  @include breakpoint(break-1) {
    float: left;
    border-bottom: 0 none;
  }

  @include breakpoint(break-2) {
    float: none;
    border-bottom: 1px solid $color-grey-medium;
  }

  & strong {
    color: rgba($color-text-alpha, .75);
  }
}

.module-contact-card .card__contact {
  @include breakpoint(break-1) {
    width: calc(50% + 1px);
    margin-right: -1px;
    box-shadow: inset -1px 0 0 0 rgba($color-grey-medium, 1);
  }

  @include breakpoint(break-2) {
    width: 100%;
    margin-right: 0;
    box-shadow: none;
  }
}

.module-contact-card .card__times {
  @include breakpoint(break-1) {
    width: 50%;
    box-shadow: inset 1px 0 0 0 rgba($color-grey-medium, 1);
  }

  @include breakpoint(break-2) {
    width: 100%;
    box-shadow: none;
  }
}

.module-contact-card .contact-card-accordion .accordion__title span {
  display: block;
  position: relative;
  cursor: pointer;
  padding-right: 20px;

  &:after {
    position: absolute;
    right: 0;
    top: 50%;
    color: $color-text-beta;
    transform: translateY(-50%);
    transition: transform .35s $easing-alpha-out;
  }
}

.module-contact-card .contact-card-accordion.-opened .accordion__title span:after {
  transform: translateY(-50%) rotate(90deg);
}

.module-contact-card .contact-card-accordion .accordion__content {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  pointer-events: none;
  transform: translateY(-20px);
  transition: opacity .35s $easing-alpha-out, max-height 0s $easing-alpha-out .35s, padding 0s $easing-alpha-out .35s, transform .35s $easing-alpha-out;
}

.module-contact-card .contact-card-accordion.-opened .accordion__content {
  opacity: 1;
  max-height: 9999px;
  padding-top: 5px;
  pointer-events: auto;
  transform: translateY(0);
  transition: opacity .35s $easing-alpha-out, max-height 0s $easing-alpha-out, padding 0s $easing-alpha-out, transform .35s $easing-alpha-out;
}

.module-contact-card .card__button {
  clear: both;
  @include ms-padding(2);

  @include breakpoint(break-1) {
    box-shadow: inset 0 1px 0 0 rgba($color-grey-medium, 1);
  }

  @include breakpoint(break-2) {
    box-shadow: none;
  }

  & a {
    position: relative;
    display: inline-block;
    color: $color-alpha;
    @include font-weight-body(bold);
    cursor: pointer;
    padding-right: 10px;

    &:after {
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
