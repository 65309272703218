// --------------------------------------------------------------------------
// Modules / Card Product Nav
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-card-product-nav {
  display: flex;
  flex-wrap: wrap;

  @include breakpoint(break-1) {
    flex-wrap: nowrap;
  }
}

.module-card-product-nav .card-product-nav__card {
  position: relative;
  width: 100%;
  margin-bottom: 1px;
  cursor: pointer;

  @include breakpoint(break-1) {
    margin-right: 1px;
    margin-bottom: 0;
  }

  &:last-child {
    margin: 0;
  }

  &:hover {
    & .card__content .content__link {
      @include ms-height(3);
      opacity: 1;
      @include ms-margin-top(2);
    }
  }
}

.module-card-product-nav .card__image {
  position: relative;
  width: 100%;
  height: 25vh;
  min-height: 150px;
  overflow: hidden;

  @include breakpoint(break-1) {
    width: auto;
    height: auto;
    min-height: 0;
  }

  @include breakpoint(break-4) {
    display: flex;
    align-items: center;
    max-height: 600px;
  }

  & .module-image-preloaded {
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);

    @include breakpoint(break-1) {
      position: static;
      left: auto;
      top: auto;
      transform: none;
    }
  }
}

.module-card-product-nav .card__wrap {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.module-card-product-nav .card__content {
  width: 100%;
  text-align: center;
  @include ms-padding(1 2 1 2, break-0);
  @include ms-padding(0 1 0 1, break-1);
  @include ms-padding(1 2 1 2, break-2);
  @include ms-padding(4 7 5 7, break-3 to break-4);
  background: linear-gradient(to bottom, rgba(0, 17, 32, 0), rgba(0, 17, 32, 1));

  & h2 {
    color: $white;
    @include ms-font-size(1, break-0);
    @include ms-font-size(1, break-1);
    @include ms-font-size(2, break-2);
    @include ms-font-size(3, break-3 to break-4);

    @include breakpoint(break-1) {
      text-transform: none;
    }

    @include breakpoint(break-2) {
      text-transform: uppercase;
    }
  }

  & h3 {
    color: rgba($white, .75);
    @include ms-font-size(-1, break-0);
    @include ms-font-size(-1, break-1);
    @include ms-font-size(0, break-2);
    @include ms-font-size(1, break-3 to break-4);

    @include breakpoint(break-1) {
      text-transform: none;
    }

    @include breakpoint(break-2) {
      text-transform: uppercase;
    }
  }

  @include breakpoint(break-1) {
    text-align: left;
  }
}

.module-card-product-nav .card__content .content__link {
  height: 0;
  overflow: hidden;
  opacity: 0;
  @include ms-margin-top(zero);
  transition: height .3s $easing-alpha-out, opacity .3s $easing-alpha-out, margin .3s $easing-alpha-out;

  & span {
    position: relative;
    color:  $white;
    @include font-weight-body(bold);
    @include ms-font-size(-1, break-0 to break-1);
    @include ms-font-size(0, break-2 to break-4);
    @include ms-padding-right(0);

    &:after {
      position: absolute;
      top: 50%;
      left: 100%;
      transform: translate3d(0,-50%,0);
    }
  }
}
