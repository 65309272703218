// --------------------------------------------------------------------------
// Ext / Press
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. List


// --------------------------------------------------------------------------
// 01. List
// --------------------------------------------------------------------------

.ext-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .images__image {
    @include ms-margin-bottom(4);
    cursor: pointer;

    @include breakpoint(break-1) {
      width: span(4);
    }

    & img {
      overflow: hidden;
      border-radius: $border-radius;
    }

    & .image__title {
      @include ms-margin-top(0);

      & span {
        color: $color-alpha;
        @include font-weight-body(bold);
      }
    }
  }

  .galleries__image {
    @include ms-margin-bottom(4);

    @include breakpoint(break-1) {
      width: span(4);
    }

    & img {
      overflow: hidden;
      border-radius: $border-radius;
    }

    & .image__title {
      @include ms-margin-top(0);

      & span {
        color: $color-alpha;
        @include font-weight-body(bold);
      }
    }
  }
}
