// --------------------------------------------------------------------------
// Modules / Page
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 00. Page
// 01. Page Header
// 01a. Page Header - Area One
// 01b. Page Header - Area Two
// 01c. Page Header - Area Three
// 01d. Page Header - Breadcrumb
// 02. Page Content
// 03. Page Footer


// --------------------------------------------------------------------------
// 00. Page
// --------------------------------------------------------------------------

.page {
  padding-top: 60px;
  transition: padding .35s $easing-alpha-out;

  @include breakpoint(break-2) {
    padding-top: 90px;
  }
}

// --------------------------------------------------------------------------
// 01. Page Header
// --------------------------------------------------------------------------

.page__header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  transition: transform .35s $easing-alpha-out;
}

.page__header.hrjs--not-top.hrjs--unpinned {
  transform: translateY(-100%);
}

// --------------------------------------------------------------------------
// 01a. Page Header - Area One
// --------------------------------------------------------------------------

.page__header .header__area--one {
  display: flex;
  align-items: center;
  position: relative;
  height: 60px;
  background-color: $white;
  z-index: 10;
  transition: box-shadow .35s $easing-alpha-out;

  @include breakpoint(break-2) {
    height: 90px;
  }

  &.-open {
    box-shadow: 0 0 10px 0 rgba($black, .15);
  }
}

.page__header.hrjs--not-top .header__area--one {
  box-shadow: 0 0 10px 0 rgba($black, .15);
}

.page__header .header__logo {
  display: flex;
  align-items: center;
  flex-grow: 0;
  width: 46px;
  height: 73px;
  margin-left: 3vw;
  @include breakpoint(break-2) {
    width: 70px;
    height: 83px;
  }
}

.page__header .header__logo a {
  width: 46px;
  @include breakpoint(break-2) {
    width: 70px;
  }
}

.page__header .header__quicknav {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;

  & > ul {
    display: none;

    @include breakpoint(break-1) {
      display: block;
    }
  }
}

.page__header .header__member {
  display: none;
  flex-grow: 0;
  width: 210px;
  padding: 0 2vw;


  @include breakpoint(break-2) {
    display: block;
    width: calc(120px + 10vw);
  }

  @include breakpoint(break-3) {
    width: calc(120px + 12vw);
  }

  & a {
    display: block;
    line-height: 1;
    @include ms-font-size(0);
    padding: 0 1vw;
  }
}

.page__header .header__mainmenu-toggle {
  flex-grow: 0;
  position: relative;
  width: 65px;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
  background-color: $color-alpha;
  z-index: 5;
  display:block;
  @include breakpoint(break-1) {
    display:none;
  }
}

.page__header .header__mainmenu-toggle .more-button {
  display: flex;
  align-items: center;
  position: absolute;
  left: 44px;
  top: 50%;
  height: 20px;
  cursor: pointer;
  z-index: 100;
  transform: translateY(-50%);
}

.page__header .header__mainmenu-toggle .more-button .more-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 95%;
  top: -1px;
  -webkit-backface-visibility: hidden;
}

.page__header .header__mainmenu-toggle .more-button .more-icon .bubble {
  position: absolute;
  width: 4px;
  height: 4px;
  left: 8px;
  top: 8px;
  border-radius: 10px;
  background-color: $white;
}

.page__header .header__mainmenu-toggle .more-button .more-icon .bubble:first-child {
  transform: translateY(-7px);
  animation: .15s $easing-alpha-out 0s 1 forwards more-button-dots-first-out;
}

.page__header .header__mainmenu-toggle .more-button .more-icon .bubble:last-child {
  transform: translateY(7px);
  animation: .15s $easing-alpha-out 0s 1 forwards more-button-dots-last-out;
}

.page__header .header__mainmenu-toggle .more-button.-open .more-icon .bubble {
  transform: translateY(0);
}

.page__header .header__mainmenu-toggle .more-button.-open .more-icon .bubble:first-child {
  animation: .35s $easing-alpha-out 0s 1 forwards more-button-dots-first-in;
}

.page__header .header__mainmenu-toggle .more-button.-open .more-icon .bubble:last-child {
  animation: .35s $easing-alpha-out 0s 1 forwards more-button-dots-last-in;
}

@keyframes more-button-dots-first-in {
  0% { width: 4px; height: 4px; transform: translateY(-7px) rotate(0deg); }
  50% { width: 4px; height: 4px; transform: translateY(0) rotate(0deg); }
  100% { width: 100%; height: 3px; transform: translateX(-7.5px) translateY(0) rotate(45deg); }
}

@keyframes more-button-dots-first-out {
  0% { width: 100%; height: 3px; transform: translateX(-7.5px) translateY(0) rotate(45deg); }
  50% { width: 4px; height: 4px; transform: translateY(0) rotate(0deg); }
  100% { width: 4px; height: 4px; transform: translateY(-7px) rotate(0deg); }
}

@keyframes more-button-dots-last-in {
  0% { width: 4px; height: 4px; transform: translateY(7px) rotate(0deg); }
  50% { width: 4px; height: 4px; transform: translateY(0) rotate(0deg); }
  100% { width: 100%; height: 3px; transform: translateX(-7.5px) translateY(0) rotate(-45deg); }
}

@keyframes more-button-dots-last-out {
  0% { width: 100%; height: 3px; transform: translateX(-7.5px) translateY(0) rotate(-45deg); }
  50% { width: 4px; height: 4px; transform: translateY(0) rotate(0deg); }
  100% { width: 4px; height: 4px; transform: translateY(7px) rotate(0deg); }
}

// --------------------------------------------------------------------------
// 01b. Page Header - Area Two
// --------------------------------------------------------------------------

.page__header .header__area--two {
  position: absolute;
  width: 100%;
  max-height: calc(100vh - 60px);
  left: 0;
  top: 60px;
  overflow-y: auto;
  background-color: $color-text-alpha;
  transform: translateY(-105%);
  z-index: 9;
  transition: height .6s $easing-alpha-out, box-shadow .35s $easing-alpha-out, transform .6s $easing-alpha-out;

  @include breakpoint(break-1) {
    max-height: none;
    overflow: visible;
  }

  @include breakpoint(break-2) {
    top: 90px;
  }

  &.-open {
    box-shadow: 0 0 10px 0 rgba($black, .15);
    transform: translateY(0);
  }
}

.page__header .header__area--two .header__mainmenu {
  position: relative;
}

.page__header .header__area--two .mainmenu__member {
  @include ms-margin-bottom(1);

  @include breakpoint(break-1) {
    display: none;
  }

  & a {
    display: block;
    width: 100%;
    color: $white;
    @include ms-font-size(-1);
    @include font-weight-body(bold);
    text-transform: uppercase;
    text-align: center;
    @include ms-padding(-1 2);
    background-color: $color-text-beta;

    &:hover {
      background-color: darken($color-text-beta, 5%);
    }
  }
}

.page__header .header__area--two .mainmenu__specialbutton {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);

  @include breakpoint(break-1) {
    display: block;
  }

  & a {
    display: block;
    color: $white;
    @include font-weight-body(bold);
    @include ms-padding(-1 2);
    background-color: $color-text-beta;
    transition: background .35s $easing-alpha-out;

    &:visited {
      color: $white;
    }

    &:hover,
    &:active,
    &:focus {
      color: $white;
      background-color: darken($color-text-beta, 5);
    }
  }
}

.page__header .header__area--two .mainmenu__quicknav {
  @include ms-margin-top(1);
  display:none;
}

// --------------------------------------------------------------------------
// 01c. Page Header - Area Three
// --------------------------------------------------------------------------

.page__header .header__area--three {
  opacity: 1;
  transform: translateY(-30px);
}

.-mainmenu2nd-present .page__header .header__area--three {
  opacity: 1;
  position: absolute;
  width: 100%;
  height: 60px;
  left: 0;
  top: 60px;
  padding: 0;
  background-color: $color-text-alpha;
  z-index: 8;
  transform: translateY(0);

  @include breakpoint(break-2) {
    top: 90px;
    padding: 0 3vw;
  }
}

.-mainmenu2nd-present .page__header .header__area--three .header__mainmenu2nd .swiper-nav-button {
  display: block;
  position: absolute;
  width: 35px;
  height: 100%;
  top: 0;
  cursor: pointer;
  z-index: 10;
  transition: left .35s $easing-alpha-out, right .35s $easing-alpha-out;

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 5vw;
    height: 100%;
    background-color: transparent;
  }

  &.swiper-nav-button--prev {
    left: 0;
    background: $color-text-alpha url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik03LjcwMSwxNC4yNzZsOS41ODYtOS41ODVjMC44NzktMC44NzgsMi4zMTctMC44NzgsMy4xOTUsMGwwLjgwMSwwLjhjMC44NzgsMC44NzcsMC44NzgsMi4zMTYsMCwzLjE5NCAgTDEzLjk2OCwxNmw3LjMxNSw3LjMxNWMwLjg3OCwwLjg3OCwwLjg3OCwyLjMxNywwLDMuMTk0bC0wLjgwMSwwLjhjLTAuODc4LDAuODc5LTIuMzE2LDAuODc5LTMuMTk1LDBsLTkuNTg2LTkuNTg3ICBDNy4yMjksMTcuMjUyLDcuMDIsMTYuNjIsNy4wNTQsMTZDNy4wMiwxNS4zOCw3LjIyOSwxNC43NDgsNy43MDEsMTQuMjc2eiIgZmlsbD0iIzE3NzkzQSIvPjwvc3ZnPg==') no-repeat 50% 48% / contain;

    &:before {
      right: 75%;
    }

    &.swiper-button-disabled {
      left: calc((3vw + 30px) * -1);
    }
  }

  &.swiper-nav-button--next {
    right: 0;
    background: $color-text-alpha url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz48c3ZnIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDMyIDMyIiBoZWlnaHQ9IjMycHgiIGlkPSJMYXllcl8xIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAzMiAzMiIgd2lkdGg9IjMycHgiIHhtbDpzcGFjZT0icHJlc2VydmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxwYXRoIGQ9Ik0yNC4yOTEsMTQuMjc2TDE0LjcwNSw0LjY5Yy0wLjg3OC0wLjg3OC0yLjMxNy0wLjg3OC0zLjE5NSwwbC0wLjgsMC44Yy0wLjg3OCwwLjg3Ny0wLjg3OCwyLjMxNiwwLDMuMTk0ICBMMTguMDI0LDE2bC03LjMxNSw3LjMxNWMtMC44NzgsMC44NzgtMC44NzgsMi4zMTcsMCwzLjE5NGwwLjgsMC44YzAuODc4LDAuODc5LDIuMzE3LDAuODc5LDMuMTk1LDBsOS41ODYtOS41ODcgIGMwLjQ3Mi0wLjQ3MSwwLjY4Mi0xLjEwMywwLjY0Ny0xLjcyM0MyNC45NzMsMTUuMzgsMjQuNzYzLDE0Ljc0OCwyNC4yOTEsMTQuMjc2eiIgZmlsbD0iIzE3NzkzQSIvPjwvc3ZnPg==') no-repeat 50% 48% / contain;

    &:before {
      left: 75%;
    }

    &.swiper-button-disabled {
      right: calc((3vw + 30px) * -1);
    }
  }
}

// --------------------------------------------------------------------------
// 01d. Page Header Breadcrumb
// --------------------------------------------------------------------------

.template-default .page__content {
  @include breakpoint(break-2) {
    margin-top: 60px;
  }
}
.page__content .content__breadcrumb {
  padding: 0;
  margin:0;
}

.page__content .content__breadcrumb .col-wrapper {
  position: relative;
}

.page__content .content__breadcrumb .breadcrumb__info {
  height: 60px;
  padding: 0;
  background-color: $color-text-alpha;
  color: rgba($white, .75);
  line-height:100%;
  text-align: center;
  @include breakpoint(break-1) {
    text-align: left;
    padding: 0 3vh;
  }

  & .info__text {
    height:100%;
    display: flex;
    align-items: center;
    @include ms-padding(zero 2);
    @include ms-font-size(-1);
    @include breakpoint(break-1) {
      @include ms-font-size(0);
    }

    & strong {
      color: $white;
      @include font-weight-body(bold);
      @include ms-margin-right(1);
    }
  }
}


// --------------------------------------------------------------------------
// 02. Page Content
// --------------------------------------------------------------------------

.page__content .content__item {
  font-weight:normal;
}


// --------------------------------------------------------------------------
// 03. Page Footer
// --------------------------------------------------------------------------

.page__footer {
  background-color: $color-text-alpha;
}

.page__footer .footer__breadcrumb {
  @include ms-padding(3 zero 9 zero);
  background-color: $white;
}

.page__footer .footer__breadcrumb .col-wrapper {
  position: relative;
}

.page__footer .footer__breadcrumb .breadcrumb__btt {
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  cursor: pointer;
  transform: translateY(-50%);

  @include breakpoint(break-2) {
    display: block;
  }

  &:hover:before {
    color: $white;
    background-color: $color-text-beta;
  }

  &:before {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 25px;
    height: 25px;
    left: -25px;
    top: 50%;
    color: $color-text-beta;
    margin-top: -13px;
    border-radius: 100%;
    border: 2px solid $color-text-beta;
    transition: color .35s $easing-alpha-out, background .35s $easing-alpha-out;
  }

  & span {
    display: inline-block;
    @include ms-padding-left(0);
  }
}

.page__footer .footer__newsletter {
  max-width: 600px;
  margin: 0 auto;
  margin-top: -90px;
}

.page__footer .newsletter {
  background-color: $color-alpha;
  @include ms-padding(3 4 4 4);

  @include breakpoint(break-1) {
    border: 5px solid $color-alpha;
    border-radius: $border-radius;
  }

  & span.heading {
    color: $white;
  }
}

.page__footer .newsletter .wrapper {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  @include breakpoint(break-1) {
    flex-wrap: nowrap;
  }
}

.page__footer .newsletter input[type="email"] {
  width: 100%;
  height: auto;
  @include ms-padding(0 2, break-0);
  @include ms-padding(1, break-1 to break-4);
  margin: 0;
  @include ms-margin(zero zero 0 zero, break-0);
  @include ms-margin(zero, break-1 to break-4);
  border-radius: 0;
  border:none;
  box-shadow: 0 3px 0 0 $color-box-shadow;
  line-height: $line-height;
  background-color: $white;

  @include breakpoint(break-1){
    line-height: normal;
  }
}

.page__footer .newsletter button[type="submit"] {
  background-color: transparent;
  border: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65px;
  height: 65px;
  padding: 18px;
  position: absolute;
  right: 0;
  top: 0;

  @include breakpoint(break-1) {
    //opacity: 0;
    position: relative;
    width: 69px;
    height: 69px;
    padding: 0 18px;
    background-color: $white;
    box-shadow: inset 0 0 0 2px $white, 0 3px 0 0 $color-box-shadow;
    transition: opacity .35s $easing-alpha-out, background .35s $easing-alpha-out, transform .35s $easing-alpha-out;
  }

  & svg path {
    fill: $color-alpha;
  }

  &:hover {
    @include breakpoint(break-1) {
      background-color: $color-alpha;
    }

    & svg path {
      @include breakpoint(break-1) {
        fill: $white;
      }
    }
  }

  & svg path {
    transition: fill .35s $easing-alpha-out;
  }
}

.page__footer .newsletter.-filled button[type="submit"] {
  opacity: 1;
  transform: translateX(0px);
}

.page__footer .footer__seo-nav {
  color: rgba($white, .5);
  @include ms-font-size(-1);

  .seo-nav__content {
    & a {
      color: inherit;

      &:hover {
        color: $color-alpha;
      }

    }
  }
}

.page__footer .footer-wrap--bottom-bar {
  border-top: 1px solid rgba($white, .20);
  @include ms-padding-top(1);
}

.page__footer .module-copyright {
  color: rgba($white, .5);
  text-align: center;

  @include breakpoint(break-1) {
    text-align: left;
  }
}
