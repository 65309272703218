// --------------------------------------------------------------------------
// Modules / Card Holo
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-card-holo > a {
  display: block;
  position: relative;
  @include ms-padding-bottom(2);
  overflow: hidden;
  border-radius: $border-radius;
  z-index: 2;
  transition: background .2s $easing-alpha-in-out, box-shadow .2s $easing-alpha-in-out;

  &:hover {
    background-color: $white;
    box-shadow: 0 10px 20px 0 rgba(189, 189, 189, .5);
  }
}

.module-card-holo > a .card-holo__image {
  position: relative;
  @include ms-margin-bottom(1);
  overflow: hidden;
  z-index: 2;
  transition: border-radius .5s $easing-alpha-in-out;

  & img {
    width: 100%;
    height: auto;
    border-radius: $border-radius;
    transition: transform 8s $easing-alpha-out;
  }
}

.module-card-holo > a:hover .card-holo__image {
  border-radius: $border-radius $border-radius 0 0;

  & img {
    transform: scale(1.04);
    backface-visibility: hidden;
  }
}

.module-card-holo > a .card-holo__headline-paragraph {
  @include ms-padding(zero 2);

  & p {
    @include ms-margin-top(2);
    color: $color-text-alpha;
  }
}

.module-card-holo > a .card-holo__button {
  display: flex;
  justify-content: center;
  @include ms-margin-top(4);
}
