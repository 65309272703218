// --------------------------------------------------------------------------
// Base / Modifiers
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Background color modifier
// 02. Color modifier
// 03. Structural modifier
// 04. Link/Button modifier
// 05. Various modifier


// --------------------------------------------------------------------------
// 01. Background color modifier
// --------------------------------------------------------------------------

.-bg-black {
  background-color: $black;
}

.-bg-white {
  background-color: $white;
}

.-bg-alpha {
  background-color: $color-alpha;
}

.-bg-grey-light {
  background-color: $color-grey-light;
}

.-bg-grey-medium {
  background-color: $color-grey-medium;
}

.-bg-grey-dark {
  background-color: $color-grey-dark;
}

// --------------------------------------------------------------------------
// 02. Color modifier
// --------------------------------------------------------------------------

.-color-white {
  color: $white;
}

.-color-alpha {
  color: $color-alpha;
}

// --------------------------------------------------------------------------
// 03. Structural modifier
// --------------------------------------------------------------------------

.-padded {
  @include ms-padding($spacing-delta zero);
}

.-padded-narrow {
  @include ms-padding(1 zero);
}

.-padded-narrowdouble {
  @include ms-padding(2 zero);
}

.-padded-wide {
  @include ms-padding($spacing-epsilon zero);
}

.-padded-wide--mod-bottom-only {
  @include ms-padding(zero zero $spacing-epsilon);
}

.-padded-wide--mod-top-only {
  @include ms-padding($spacing-epsilon zero zero);
}

.-max-width-limited {
  @include maxwidth;
  margin-left: auto;
  margin-right: auto;
}

.-centered {
  text-align: center;
}

.col-wrapper.-top-margin-double {
  @include ms-margin-top($spacing-vertical-gutter * 2);
}

.col-wrapper.-top-margin-section {
  @include ms-margin-top($spacing-epsilon);

  &:first-child {
    margin-top: 0;
  }
}

.col-wrapper.-bottom-margin-double {
  @include ms-margin-bottom($spacing-vertical-gutter * 2);

}

.col-wrapper.-bottom-margin-section {
  @include ms-margin-bottom($spacing-epsilon);

  &:last-child {
    margin-bottom: 0;
  }
}

// --------------------------------------------------------------------------
// 04. Link/Button modifier
// --------------------------------------------------------------------------

.-btn-alpha {
  display: inline-block;
  position: relative;
  height: 2.5em;
  background-color: $color-alpha;
  text-align: center;
  @include font-weight-body(bold);
  @include ms-padding(zero 1);
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: 0 0 0 1px $color-text-beta, inset 0 0 0 1px $color-text-beta;
  transition: all .10s $easing-alpha-in-out .10s;

  &:visited {
    color: $color-text-beta;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: darken($color-alpha, 5%);
    box-shadow: 0 0 0 1px darken($color-text-beta, 5%), inset 0 0 0 1px darken($color-text-beta, 5%);
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    z-index: 3;
    color: $white;
  }
}

.-btn-holo {
  display: inline-block;
  position: relative;
  height: 2.5em;
  color: $color-text-beta;
  text-align: center;
  @include font-weight-body(bold);
  @include ms-padding(zero 1);
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: 0 0 0 1px $color-text-beta, inset 0 0 0 1px $color-text-beta;
  transition: all .10s $easing-alpha-in-out .10s;

  &:visited {
    color: $color-text-beta;
  }

  &:hover,
  &:active,
  &:focus {
    color: $white;
    background-color: $color-alpha;
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    z-index: 3;
  }
}

// --------------------------------------------------------------------------
// 05. Various
// --------------------------------------------------------------------------

.-shadow {
  box-shadow: 0 1px 3px 0 rgba($black, .25);
}

.-keyvisual {
  background-position: 50% 0;
  background-repeat: no-repeat;
}

.-keyvisual-cover {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.-keyvisual-contain {
  background-position: 50% 0;
  background-repeat: no-repeat;
  background-size: contain;
}

.-check-list ul{
  margin: 0;
  padding: 0;
  margin-left: 1.5em;
  @include ms-margin-top(1);
}

.-check-list ul > li{
  position: relative;
  margin-bottom: 10px;
  padding-left: 1em;
  min-height: 2.4em;
  display: flex;
  align-items: center;
}

.-check-list ul > li:before {
  position: absolute;
  top: 3px;
  left: -1em;

  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-alpha;

  content: "\ea10";
  font-family: 'icomoon';
  speak: none;
  font-size: 30px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
