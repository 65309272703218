// --------------------------------------------------------------------------
// Modules / Content Item
// --------------------------------------------------------------------------

.content-item {
  @include ms-margin(zero zero $spacing-vertical-gutter);
  @if $feature-enable-debugging {
    border: 1px solid magenta;
  }
}

.content-item:last-child {
  @include ms-margin-bottom(zero);
}
