// --------------------------------------------------------------------------
// Modules / Callout
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-callout {
  @include ms-padding(4 zero, break-0 to break-1);
  @include ms-padding(0 zero, break-2 to break-4);
  background-color: $color-alpha;
}

.module-callout .callout__twocol {
  text-align: center;

  @include breakpoint(break-2) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    text-align: left;
  }

  & > div {
    @include breakpoint(break-2) {
      width: 48%;
    }
  }
}

.module-callout .content__title {
  @include ms-margin-bottom(1);

  & span {
    color: $white;
  }
}

.module-callout .callout__image {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  @include ms-margin-top(2, break-0 to break-1);
  @include ms-margin-top(zero, break-2 to break-4);

  @include breakpoint(break-2) {
    max-width: none;
  }
}
