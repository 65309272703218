// --------------------------------------------------------------------------
// Vendor / Swiper - CM Overrides
// --------------------------------------------------------------------------

html {
  .swiper-container {
    padding-bottom: 35px;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    width: 20px;
    height: 20px;
  }

  .swiper-pagination-bullet {
    opacity: 1;
    background: $color-grey-medium;
    transition: background .25s $easing-alpha-out, box-shadow .25s $easing-alpha-out;
  }

  .swiper-pagination-bullet-active {
    background: $color-alpha;
    box-shadow: 0 0 0 2px $color-alpha;
  }

  .swiper-slide {
    opacity: .5;
    transition: opacity .35s $easing-alpha-out;

    &.swiper-slide-active {
      opacity: 1;
    }
  }

  .swiper-container-free-mode .swiper-slide {
    opacity: 1;
  }
}
