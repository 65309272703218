// --------------------------------------------------------------------------
// Base / Tables
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Basic setup


// --------------------------------------------------------------------------
// 01. Basic setup
// --------------------------------------------------------------------------

table {
  border-collapse: collapse;
  width: 100%;
}

tr,
td,
th {
  vertical-align: middle;
}

tr.tr-even {
  background-color: $white;
}

tr.tr-odd {
  background-color: rgba($color-grey-dark, .1);
}

thead {
  background-color: $color-alpha;
}

th,
td {
  @include ms-padding(-2 1);
  text-align: left;
}

th > span {
  color: $white;
  @include font-weight-body(bold);
}

td > span {
  @include ms-font-size(-1);
}

.table-mobile thead {
  display: none;
}

table.table-mobile,
.table-mobile tbody {
  display: block;
  width: 100%;
}

.table-mobile tr {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.table-mobile td:first-child {
  position: relative;
  background-color: $color-alpha!important;
  height: auto;
  @include ms-padding(-2 1);
  overflow: visible;
  cursor: pointer;
  opacity: 1;

  &:after {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    content: '\e901';
    position: absolute;
    top: 50%;
    @include ms-right(1);
    transform: translate3d(0, -50%, 0);
    will-change: transform;
    transition: transform .35s $easing-alpha-out;

    color: $white;
  }

  & > span {
    @include ms-font-size(0);
    @include font-weight-body(bold);
    color: $white;
  }

  & > .td_label {
    display: none;
  }
}

.table-mobile td {
  display: flex;
  width: 100%;
  height: 0;
  padding: 0;
  overflow: hidden;

  & > span {
    width: 60%;
  }

  & > .td_label {
    width: 40%;
    @include ms-font-size(-1);
  }
}

.table-mobile tr.-active td:first-child {
  animation: none;

  &:after {
    transform: translate3d(0, -50%, 0) rotate(90deg);
  }
}

.table-mobile tr.-active td {
  height: auto;
  @include ms-padding(-2 1);
  overflow: visible;
  animation: .15s $easing-alpha-out 0s 1 forwards tab-show-2;
}

.table-mobile tr.tr-even,
.table-mobile tr.tr-odd {
  background-color: transparent;
  border-bottom: 1px solid $white;
}

.table-mobile td.td-even {
  background-color: $white;
}

.table-mobile td.td-odd {
  background-color: rgba($color-grey-dark, .1);
}
