// --------------------------------------------------------------------------
// Base / Typography
// --------------------------------------------------------------------------


// TABLE OF CONTENTS
// -----------------
// 01. Root
// 02. Headings
// 03. Paragraphs & Links
// 04. Lists
// 05. Various
// 06. Transform Helpers


// --------------------------------------------------------------------------
// 01. Root - html, body, ...
// --------------------------------------------------------------------------

html {
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

// Body - OpenType ligatures, discretionary ligatures and lining figures enabled if available.
body {
  @include rootsize;
  color: $color-text-alpha;
  @include font-weight-body(regular);
  @include font-family-body;
  font-style: normal;
  line-height: $line-height;
  @if $feature-enable-font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}


// --------------------------------------------------------------------------
// 02. Headings
// --------------------------------------------------------------------------

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

// Headings
h1,
h2,
h3,
h4,
h5,
h6,
.heading {
  margin: 0;
  line-height: 1.4;
  @include font-family-heading;
  @include font-weight-heading(bold);
  font-style: normal;
}

// Heading level 1
h1,
.alpha {
  @include ms-font-size(4);
  color: $color-alpha;
}

// Heading level 2
h2,
.beta {
  @include ms-font-size(3);
  color: $color-alpha;
}

// Heading level 3
h3,
.gamma {
  @include ms-font-size(2);
  color: $color-alpha;
}

// Heading level 4
h4,
.delta {
  @include ms-font-size(1);
  color: rgba($color-text-alpha, .75);
}

// Heading level 5
h5,
.epsilon {
  @include ms-font-size(0);
  color: rgba($color-text-alpha, .75);
}

// Heading level 6
h6,
.zeta {
  @include ms-font-size(0);
  color: rgba($color-text-alpha, .75);
}

h4 + h2,
h4 + h1 {
  margin-top: .4em;
}


// --------------------------------------------------------------------------
// 03. Paragraphs & Links
// --------------------------------------------------------------------------

a {
  text-decoration: none;
  color: $color-text-beta;
  transition: color .25s $easing-alpha-out;

  &:visited {
    color: $color-text-beta;
  }

  &:hover,
  &:active,
  &:focus {
    color: darken($color-text-beta, 3%);
    text-decoration: underline;
  }
}

.paragraph a {
  transition: box-shadow .25s $easing-alpha-out;
}

p,
.paragraph {
  @include ms-margin(zero zero 0);
  &:last-child {
    @include ms-margin-bottom(zero);
  }

  &.paragraph--decreased-width {
    @include breakpoint(break-2) {
      padding-left: 15%;
      padding-right: 15%;
    }

    @include breakpoint(break-3) {
      padding-left: 20%;
      padding-right: 20%;
    }

    @include breakpoint(break-4) {
      padding-left: 25%;
      padding-right: 25%;
    }
  }

  &.paragraph--decreased-width-left {
    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      max-width: 550px;
    }

    & p {
      max-width: 880px;
    }
  }
}

// Paragraph is adjacent sibling of any heading
h1,
h2,
h3,
h4,
h5,
h6,
.heading {
  & + p {
    @include ms-margin-top($spacing-heading-paragraph);
  }
}


// --------------------------------------------------------------------------
// 04. Lists
// --------------------------------------------------------------------------

ul,
ol,
dt,
dd {
  padding: 0;
  margin: 0;
}

// Lists
ul,
ol {
  li {
    list-style-type: none;
  }
}


// --------------------------------------------------------------------------
// 05. Various
// --------------------------------------------------------------------------

// Bold
b,
strong,
.bold {
  @include font-weight-body(bold);
}

// Italic
em,
i,
.italic {
  font-style: map-get($type-body, italic);
}

// Caption and inline small text
small,
.caption {
  @include font-family-heading;
  @include font-weight-heading(regular);
  @include ms-font-size(-1);
  font-style: normal;
}

small {
  line-height: 1;
}

.caption {
  color: lighten($color-text-alpha, 10%);
}

// Nice spacing for captions
h1 + .caption,
.alpha + .caption,
h2 + .caption,
.beta + .caption,
h3 + .caption,
.gamma + .caption {
  margin-top: -0.45em;
}

// Code block
code,
pre {
  @include font-family-monospace;
  background-color: lighten($color-alpha, 40%);
}

pre {
  border: 1px solid $color-grey-dark;
  display: block;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 0.3rem;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
}

code {
  @include ms-font-size(-1);
  border-radius: 3px;
  line-height: 1;
  border: 1px solid $color-grey-dark;
  white-space: nowrap;
  margin: 0 0.2em;
  padding: 0.2em 0.4em;
}


// --------------------------------------------------------------------------
// 06. Transform Helpers
// --------------------------------------------------------------------------

// Letter space those capitals people, Jan Tschichold would be proud
.upper {
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

// Common ligatures, discretionary ligatures, lining numerals and kerning with OpenType
.open-type {
  @if $feature-enable-ot-features {
    -webkit-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
    -moz-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
    -ms-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
    -o-font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
    font-feature-settings: 'dlig' 1, 'liga' 1, 'lnum' 1, 'kern' 1;
  }
}

// Real small caps
.small-caps {
  letter-spacing: 0.1em;
  @if $feature-enable-caps {
    -webkit-font-feature-settings: 'smcp' 1, 'kern' 1;
    -moz-font-feature-settings: 'smcp' 1, 'kern' 1;
    -ms-font-feature-settings: 'smcp' 1, 'kern' 1;
    -o-font-feature-settings: 'smcp' 1, 'kern' 1;
    font-feature-settings: 'smcp' 1, 'kern' 1;
  }
}

// Consistent height numbers with OpenType
.lining-numerals {
  @if $feature-enable-numerals {
    -webkit-font-feature-settings: 'lnum' 1, 'kern' 1;
    -moz-font-feature-settings: 'lnum' 1, 'kern' 1;
    -ms-font-feature-settings: 'lnum' 1, 'kern' 1;
    -o-font-feature-settings: 'lnum' 1, 'kern' 1;
    font-feature-settings: 'lnum' 1, 'kern' 1;
  }
}

// Ascending and descending numbers with OpenType
.oldstyle-numerals {
  @if $feature-enable-oldstyle {
    -webkit-font-feature-settings: 'onum' 1, 'kern' 1;
    -moz-font-feature-settings: 'onum' 1, 'kern' 1;
    -ms-font-feature-settings: 'onum' 1, 'kern' 1;
    -o-font-feature-settings: 'onum' 1, 'kern' 1;
    font-feature-settings: 'onum' 1, 'kern' 1;
  }
}
