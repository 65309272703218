// --------------------------------------------------------------------------
// Modules / Nav
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Quicknav
// 02. Main Navigation (Dropdown)
// 03. Second Level Navigation
// 04. Breadcrumb
// 05. Footer

// --------------------------------------------------------------------------
// 01. Quicknav
// --------------------------------------------------------------------------

// Header (Desktop)
.header__quicknav .module-nav__item {
  display: inline-block;
  padding: 0 1vw;
  @include breakpoint(break-3) {
    padding: 0 1.2vw;
  }

  & a {
    display: block;
    width: 27px;
    color: rgba($color-text-alpha, .6);
    @include ms-font-size(1);
    @include font-weight-body(bold);
    text-indent: -9999px;
    @include ms-padding(-2 zero);
    padding-left: 1.0vw;
    overflow: hidden;
    text-decoration: none;

    @include breakpoint(break-1) {
      width: auto;
      text-indent: 0;
      padding: 0 1.0vw;
      overflow: visible;
      background-position: 0 40%;
    }

    @include breakpoint(break-3) {
      padding-left: 40px;
    }

    &:hover {
      color: $color-text-alpha;
    }
  }
}

.header__quicknav .module-nav__item--active {
  & a {
    color: $color-text-alpha;
    @include font-weight-body(bold);
  }
}

.no-touchevents .header__quicknav .module-nav__item.-faded > a {
  color: rgba($color-text-alpha, .25);
}

// --------------------------------------------------------------------------
// 02. Main Navigation (Dropdown)
// --------------------------------------------------------------------------

.header__mainmenu .module-nav__lvl1 {
  display: flex;
  flex-wrap: wrap;

  @include breakpoint(break-2) {
    flex-wrap: nowrap;
  }

  @include breakpoint(break-3) {
    flex-wrap: wrap;
  }
}

.header__mainmenu .module-nav__lvl1 > .module-nav__item {
  width: 100%;
  @include ms-padding(-1 2, break-0);
  @include ms-padding(zero, break-1 to break-4);
  border-bottom: 1px solid rgba($white, .15);

  &:last-child {
    border-bottom: 0 none;
  }

  @include breakpoint(break-1) {
    width: 50%;
    padding: 5vh 10vw;
  }

  @include breakpoint(break-2) {
    width: 100%;
    padding: 10vh 5vw 20vh 5vw;
  }

  @include breakpoint(break-3) {
    width: 25%;
    padding: 10vh 2vw 20vh 5vw;
  }

  &.module-nav__item--0 {
    @include breakpoint(break-1) {
      border-right: 1px solid rgba($white, .15);
      border-bottom: 1px solid rgba($white, .15);
    }

    @include breakpoint(break-2) {
      border-bottom: 0 none;
    }
  }

  &.module-nav__item--1 {
    @include breakpoint(break-1) {
      border-bottom: 1px solid rgba($white, .15);
    }

    @include breakpoint(break-2) {
      border-right: 1px solid rgba($white, .15);
      border-bottom: 0 none;
    }
  }

  &.module-nav__item--2 {
    @include breakpoint(break-1) {
      padding-bottom: 10vh;
      border-right: 1px solid rgba($white, .15);
      border-bottom: 0 none;
    }

    @include breakpoint(break-2) {
      padding-bottom: 20vh;
    }
  }

  &.module-nav__item--3 {
    @include breakpoint(break-1) {
      padding-bottom: 10vh;
      border-bottom: 0 none;
    }

    @include breakpoint(break-2) {
      padding-bottom: 20vh;
    }
  }
}

.header__mainmenu .module-nav__lvl1 > .module-nav__item > a {
  display: block;
  position: relative;
  line-height: 1.4;
  color: $white;
  @include ms-font-size(1, break-1 to break-4);
  @include font-family-heading;
  @include font-weight-heading(medium);
  white-space: nowrap;

  @include breakpoint(break-1) {
    @include font-weight-heading(bold);
  }

  &:hover {
    @include breakpoint(break-1) {
      color: $color-text-beta;
    }
  }
}

.header__mainmenu .module-nav__lvl1 > .module-nav__item.module-nav__item--active > a {
  @include font-weight-heading(bold);
}

.header__mainmenu .module-nav__lvl1 > .module-nav__item--hasSubpages > a {
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 7px;
    height: 12px;
    right: 3px;
    top: 50%;
    margin-top: -8px;
    background: transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAgCAYAAAAffCjxAAAAs0lEQVR4AWL4//+/HhBfBuLnQFzMAGBljm0AgqIAik6o+tFRkfgVJTtqLWADtebJLYXq5Z4BTlZE7PG2ZaMzvtZMdMS/2YqwWBEmK0K1IoxWhMGK0FsROitCsSK0VoRiRWis6LKi24qqET0rpUZgF1Mj+ouokSBrqZFFaqmRaRuoUYy0UqNga6dGUdtGjcK/nxrV0QRSK8h7WAyZRk6VfRPNkBnkNiKcgPgWEL8G4okMZAIA59ebHgexpwYAAAAASUVORK5CYII=') no-repeat 50% 50% / contain;
    transition: transform .35s $easing-alpha-out;

    @include breakpoint(break-1) {
      display: none;
    }
  }
}

.header__mainmenu .module-nav__lvl1 > .module-nav__item.-opened > a:after {
  transform: rotate(90deg);
}

.header__mainmenu .module-nav__lvl2 {
  height: 0;
  @include ms-margin-top(zero, break-0);
  @include ms-margin-top(-2, break-1 to break-4);
  overflow: hidden;

  @include breakpoint(break-1) {
    height: auto;
    overflow: visible;
  }
}

.header__mainmenu .module-nav__lvl1 > .module-nav__item.-opened .module-nav__lvl2 {
  height: auto;
  @include ms-margin-top(-2);
}

.header__mainmenu .module-nav__lvl2 > .module-nav__item {
  padding: 5px 0;
}

.header__mainmenu .module-nav__lvl2 > .module-nav__item > a {
  display: block;
  position: relative;
  color: rgba($white, .75);
  @include ms-font-size(-1, break-0 to break-2);
  @include ms-font-size(0, break-3 to break-4);
  @include font-weight-body(medium);
  transition: color .25s $easing-alpha-out, padding .25s $easing-alpha-out;

  @include breakpoint(break-1) {
    text-transform: none;
  }

  @include breakpoint(break-3) {
    white-space: nowrap;
    @include font-weight-body(regular);
  }

  &:visited {
    color: rgba($white, .75);

    @include breakpoint(break-1) {
      color: $white;
    }
  }

  &:hover,
  &:active,
  &:focus {
    @include breakpoint(break-1) {
      color: $color-text-beta;
    }

    @include breakpoint(break-3) {
      padding-left: 20px;
    }

    &:before {
      opacity: 1;
      width: 15px;

      @include breakpoint(break-3) {
        transform: translateX(0);
      }
    }
  }

  &:before {
    content: '';
    display: block;
    opacity: 0;
    position: absolute;
    width: 0;
    height: 5px;
    left: 0;
    top: .6em;
    border-radius: 0;
    background-color: $color-alpha;
    transform: translateX(-25px);
    transition: opacity .25s $easing-alpha-out, width .25s $easing-alpha-out, transform .25s $easing-alpha-out;

    @include breakpoint(break-3) {
      top: .65em;
      transform: translateX(-5px);
    }
  }
}

.header__mainmenu .module-nav__lvl2 > .module-nav__item.module-nav__item--active > a {
  color: $white;
  @include font-weight-body(bold);
}

// --------------------------------------------------------------------------
// 03. Second Level Navigation
// --------------------------------------------------------------------------

.header__mainmenu2nd {
  height: 100%;
  overflow: visible;
  padding-bottom: 0 !important;
}

.header__mainmenu2nd > ul {
  height: 100%;
}

.header__mainmenu2nd > ul > .module-nav__item {
  opacity: 1;
  position: relative;
  width: auto;
  height: 100%;
  @include ms-margin-right(0, break-0);
  @include ms-margin-right(3, break-1 to break-4);
  box-shadow: inset 0 0 0 0 rgba($color-alpha, .5);
  transition: box-shadow .2s $easing-alpha-out;

  &:last-child {
    padding-right: 0;
  }

  & > a {
    display: flex;
    align-items: center;
    height: 100%;
    color: rgba($white, .75);
    @include font-weight-body(medium);
    @include ms-font-size(0);
    text-decoration: none;
  }

  &.module-nav__item--active a {
    @include font-weight-body(bold);
    color: $white;
  }
}

.no-touchevents .header__mainmenu2nd .module-nav__item.-faded > a {
  color: rgba($white, .25);
}

// --------------------------------------------------------------------------
// 04. Breadcrumb
// --------------------------------------------------------------------------

.page__content .breadcrumb__nav .nav__crumb,
.page__footer .breadcrumb__nav .nav__crumb {
  display: inline-block;
  @include font-weight-body(medium);

  &:after {
    display: inline-block;
    @include ms-padding(zero 0);
  }

  &:first-child a {
    display: inline-block;
    position: relative;
    width: 75px;
  }

  & a {
    color: $color-text-alpha;
    @include font-weight-body(bold);

    &:visited {
      color: $color-text-alpha;
    }

    &:hover,
    &:active,
    &:focus {
      color: $color-text-beta;
    }
  }
}

// --------------------------------------------------------------------------
// 05. Footer
// --------------------------------------------------------------------------

.module-nav--footer {
  text-align: center;
  @include breakpoint(break-1) {
    text-align: left;
  }
}
.module-nav--footer > ul > .module-nav__item a {
  color: rgba($white, .5);
  @include ms-font-size(0);

  &:hover {
    color:$color-alpha;
  }
}
.module-nav--footer-bottom > ul {
  display: flex;
  justify-content: center;

  @include breakpoint(break-1) {
    justify-content: flex-end;
  }
}
