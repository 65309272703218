// --------------------------------------------------------------------------
// Modules / Multi Billboard
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-multi-billboard {
  @include breakpoint(break-2) {
    display: flex;
  }
}

.module-multi-billboard .multi-billboard__billboard {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.module-multi-billboard .billboard__background {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  pointer-events: none;
  z-index: 5;
}

.module-multi-billboard .billboard__background .module-image-preloaded {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.module-multi-billboard h4,
.module-multi-billboard span.heading {
  color: $white;
  text-shadow: $text-shadow-alpha;
}

.module-multi-billboard .billboard__content {
  position: relative;
  text-align: center;
  @include ms-padding(zero 4);
  z-index: 10;

  @include breakpoint(break-2) {
    text-align: left;
    padding-left: gutter() * 0.5 + span(1);
    padding-right: span(1);
  }
}

.module-multi-billboard .content__title {
  margin-bottom: 5vh;

  @include breakpoint(break-2) {
    margin-bottom: 10vh;
  }
}

.module-multi-billboard.-count-1 .content__text {
  @include breakpoint(break-2) {
    max-width: 600px;
  }
}

.module-multi-billboard .content__button {
  margin-top: 2vh;

  @include breakpoint(break-2) {
    margin-top: 4vh;
  }
}

.module-multi-billboard .billboard__image {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  @include breakpoint(break-2) {
    max-width: 700px;
  }
}
