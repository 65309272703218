/* Classic */

.cc-floating.cc-theme-classic {
  padding: 1.2em;
  border-radius: 5px;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
  text-align: center;
  display: inline;
  flex: none;
}

.cc-theme-classic .cc-btn {
  border-radius: 5px;
  background-color: $color-alpha;
  color: $color-text-alpha;
}

.cc-window {
  background-color: $black;
  color: $white;
}

a.cc-link {
  color: $white;
}
.cc-theme-classic .cc-btn:last-child {
  min-width: 140px;
}

.cc-floating.cc-type-info.cc-theme-classic .cc-btn {
  display: inline-block;
}
