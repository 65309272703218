// --------------------------------------------------------------------------
// Modules / Accordion
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles (Display Mode 1)
// 02. Display Mode 2 Overrides
// 03. Parent Based Overrides for Display Mode 2


// --------------------------------------------------------------------------
// 01. Main Styles (Display Mode 1)
// --------------------------------------------------------------------------

.module-accordion,
.module-accordion.content-item:last-child {
  @include ms-margin-bottom(1, $negative:true);
}

.module-accordion .accordion__item {
  @include ms-margin-bottom(1);
}

.module-accordion .item__title {
  position: relative;
  @include ms-padding(1 2, break-0);
  @include ms-padding(3 4, break-1 to break-4);
  cursor: pointer;
  background-color: $white;
  box-shadow: $shadow-alpha;
  z-index: 10;
  transition: box-shadow .35s $easing-alpha-out;

  & span {
    display: block;
    position: relative;
    color: $color-text-beta;
    padding-right: 20px;

    &:after {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: transform .35s $easing-alpha-out;
    }
  }
}

.module-accordion .-opened .item__title {
  box-shadow: 0 0 20px 0 rgba(189, 189, 189, .5);
}

.module-accordion .-opened .item__title span:after {
  transform: translateY(-50%) rotate(90deg);
}

.module-accordion .item__content {
  opacity: 0;
  position: relative;
  max-height: 0;
  @include ms-padding(zero 2, break-0);
  @include ms-padding(zero 4, break-1 to break-4);
  pointer-events: none;
  overflow: hidden;
  background-color: $white;
  z-index: 5;
  box-shadow: inset 0 1px 0 0 rgba($color-grey-medium, 1), $shadow-alpha;
  transform: translateY(-40px);
  transition: opacity .35s $easing-alpha-out, max-height 0s $easing-alpha-out .35s, padding 0s $easing-alpha-out .35s, transform .35s $easing-alpha-out;
}

.module-accordion .-opened .item__content {
  opacity: 1;
  max-height: 9999px;
  @include ms-padding(1 2, break-0);
  @include ms-padding(3 4, break-1 to break-4);
  pointer-events: auto;
  transform: translateY(0);
  transition: opacity .35s $easing-alpha-out, max-height 0s $easing-alpha-out, padding 0s $easing-alpha-out, transform .35s $easing-alpha-out;
}

.module-accordion .content__twocol {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column-reverse;

  @include breakpoint(break-2) {
    flex-direction: row;
  }

  & .twocol__left {
    @include ms-margin-top(2, break-0 to break-1);
    @include ms-margin-top(zero, break-2 to break-4);

    @include breakpoint(break-2) {
      width: span(5);
    }
  }

  & .twocol__right {
    @include breakpoint(break-2) {
      width: span(7);
    }
  }
}

.module-accordion .accordion__item.-no-image .content__twocol {
  & .twocol__right {
    @include breakpoint(break-2) {
      width: 100%;
    }
  }
}

.module-accordion .content__headline {
  @include ms-margin-bottom(-1);
}

.module-accordion .content__button {
  @include ms-margin-top(1);
}

// --------------------------------------------------------------------------
// 02. Display Mode 2 Overrides
// --------------------------------------------------------------------------

.module-accordion.-mode-two .item__title {
  @include ms-padding(0 1, break-0);
  @include ms-padding(1 2, break-1 to break-4);

  & span {
    color: rgba($color-text-alpha, .75);
    text-transform: none;

    &:after {
      color: $color-text-beta;
    }
  }
}

.module-accordion.-mode-two .content__twocol {
  @include breakpoint(break-2) {
    flex-direction: row-reverse;
  }
}

.module-accordion.-mode-two .item__content {
  @include ms-padding(zero 1, break-0);
  @include ms-padding(zero 2, break-1 to break-4);
  box-shadow: $shadow-alpha;
  z-index: 15;
  transform: translateY(-20px);
}

.module-accordion.-mode-two .-opened .item__content {
  @include ms-padding(0 1, break-0);
  @include ms-padding(1 2, break-1 to break-4);
  transform: translateY(0);
}

.module-accordion.-mode-two .content__twocol .twocol__right {
  @include ms-padding-left(2);
  border-left: 6px solid $color-grey-light;
}

// --------------------------------------------------------------------------
// 03. Parent Based Overrides for Display Mode 2
// --------------------------------------------------------------------------

.l-2col-b2-58-33 .col-one .module-accordion.-mode-two .content__twocol {
  flex-direction: column;

  & .twocol__left {
    width: 100%;
    @include ms-padding-left(2);
    @include ms-padding-bottom(3);
    border-left: 6px solid $color-grey-light;
  }

  & .twocol__right {
    width: 100%;
  }
}
