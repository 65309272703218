// --------------------------------------------------------------------------
// Modules / Sidebar Menu
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-sidebar-menu {
  @include breakpoint(break-2) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &:after {
    display: none;
  }
}

.module-sidebar-menu .module-nav--sidebar {
  position: relative;
  z-index: 15;
}

.module-sidebar-menu .module-nav--sidebar > .sidebar__menu-toggle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 40px;
  padding: 11px 17px;
  cursor: pointer;
  border-radius: $border-radius;
  background-color: $color-alpha;

  @include breakpoint(break-2) {
    display: none;
  }

  &:hover .toggle__row {
    transform: scaleX(1.4);
  }

  & .toggle__title {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);

    & span {
      display: block;
      white-space: nowrap;
      padding-left: 10px;
    }
  }

  & .toggle__row {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: $border-radius;
    background-color: $white;
    transition: transform .35s $easing-alpha-out;
  }
}

.module-sidebar-menu > .col-one {
  position: relative;
  display: flex;
  @include ms-margin-bottom(2, break-0 to break-1);
  @include ms-margin-bottom(zero, break-2 to break-4);
  z-index: 15;

  @include breakpoint(break-2) {
    display: block;
    width: span(3);
  }
}

.module-sidebar-menu .module-nav--sidebar > ul {
  opacity: 0;
  position: absolute;
  min-width: 300px;
  left: 0;
  top: 100%;
  @include ms-padding(-2 0, break-0 to break-1);
  @include ms-padding(zero, break-2 to break-4);
  pointer-events: none;
  background-color: $white;
  box-shadow: $shadow-alpha;
  transform: translateY(20px);
  transition: opacity .35s $easing-alpha-out, transform .35s $easing-alpha-out;

  @include breakpoint(break-2) {
    opacity: 1;
    position: static;
    min-width: 0;
    pointer-events: auto;
    background-color: transparent;
    box-shadow: none;
    transform: translateX(0);
  }
}

.module-sidebar-menu .module-nav--sidebar.-opened > ul {
  opacity: 1;
  pointer-events: auto;
  transform: translateX(0);
}

.module-sidebar-menu > .col-two {
  @include breakpoint(break-2) {
    width: span(8);
  }
}
