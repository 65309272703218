// --------------------------------------------------------------------------
// Modules / Column Wrapper (one and more columns)
// --------------------------------------------------------------------------

.col-wrapper {
  @include clearfix;
  @include ms-margin-bottom($spacing-vertical-gutter);
  @if $feature-enable-debugging {
    border: 2px solid orange;
  }
}

.col-wrapper:last-child {
  @include ms-margin-bottom(zero);
}

