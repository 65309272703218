// --------------------------------------------------------------------------
// Base / Globals
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Various


// --------------------------------------------------------------------------
// 01. Various
// --------------------------------------------------------------------------

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  overflow: hidden;
  overflow-y: auto;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: $white;
}

// Perfect centered hr
hr {
  @include ms-padding(0 null);
  margin: 0;
  border: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to($color-alpha));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 50%, $color-alpha 50%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 50%, $color-alpha 50%);
  background-position: 0 50%;
  background-size: 2px 2px;
  background-repeat: repeat-x;
}

img {
  margin: 0 auto;
  max-width: 100%;
  width: auto;
  height: auto;
  vertical-align: middle;
  -ms-interpolation-mode: bicubic;
  & + h1,
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6,
  & + .heading {
    @include ms-margin-top($spacing-img-heading);
  }
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.transition-preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}
