// --------------------------------------------------------------------------
// Vendor / Photoswipe - CM Overrides
// --------------------------------------------------------------------------

html {
  .pswp__scroll-wrap {
    height: calc(100% - 80px);
  }

  .pswp__thumbnails-wrapper {
    position: absolute;
    width: 100%;
    height: 80px;
    left: 0;
    bottom: 0;
    padding: 0 !important;
    background-color: rgba($white, .05);
  }

  .pswp__thumbnails-wrapper .thumbnails__thumbnail {
    position: relative;
    opacity: .3;
    width: auto;
    padding: 0 1px;
    cursor: pointer;
    box-shadow: inset 0 0 0 2px rgba($color-alpha, 0);
    transition: opacity .35s $easing-alpha-out;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: calc(100% - 2px);
      height: 100%;
      left: 1px;
      top: 0;
      box-shadow: inset 0 0 0 0 rgba($color-alpha, 1);
      transition: box-shadow .35s $easing-alpha-out;
    }

    &.-ps-active {
      opacity: 1;

      &:before {
        box-shadow: inset 0 0 0 2px rgba($color-alpha, 1);
      }
    }

    & img {
      width: auto;
      max-height: 100%;
    }
  }
}
