// --------------------------------------------------------------------------
// Modules / Seperator
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-seperator .seperator__line {
  width: 100%;
  height: 1px;
  background-color: rgb(189, 189, 189);
}
