// --------------------------------------------------------------------------
// Modules / Billboard
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-billboard {
  @include ms-padding($spacing-epsilon zero);
  background-color: $color-grey-light;
  @include breakpoint(break-1) {
    padding:0;
    background-color: transparent;
  }
}

.module-billboard .billboard__item {
  position: relative;
}

.module-billboard .billboard__background {
  position: relative;
  z-index: 5;
  display:none;
  @include breakpoint(break-1) {
    display:block;
  }
}

.module-billboard .content__wrapper {
  width: 100%;
}

.module-billboard .billboard__content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 10;
  text-align: center;
  @include breakpoint(break-1) {
    text-align: left;
  }

  @include breakpoint(break-1) {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.module-billboard span.heading {
  color: $color-text-beta;
  @include breakpoint(break-1) {
    color: $white;
  }
}

.module-billboard .content__button {
  margin-top: 4vh;
}

.module-billboard .billboard__disturber {
  display:none;
  z-index: 11;
  height:100%;
  position:absolute;
  top: 74%;
  right: 30px;
  @include breakpoint(break-1) {
    display:block;
  }
  @include breakpoint(break-2) {
    top: 30px;
    right: 30px;
  }


  & img {
    width:100px;
  }
}
