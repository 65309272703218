// --------------------------------------------------------------------------
// Modules / Contact
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-contact .contact__wrap {
  @include breakpoint(break-1) {
    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }

  & > .contact__col--one {
    width: 100%;
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1 to break-4);

    @include breakpoint(break-1) {
      width: span(7);
      float: left;
    }
  }

  & > .contact__col--two {
    width: 100%;
    @include ms-margin-bottom($verticalGutter, break-0);
    @include ms-margin-bottom(zero, break-1 to break-4);

    @include breakpoint(break-1) {
      width: span(5);
      float: right;
    }

    @include breakpoint(break-2) {
      width: span(4);
    }
  }

  & > .contact__col--three {
    width: 100%;

    @include breakpoint(break-1) {
      width: span(7);
      float: left;
    }
  }
}

.module-contact .contact__infos {
  @include ms-padding(zero, break-0);
  @include ms-padding(0 zero, break-1 to break-4);
  @include ms-margin-top(2);

  @include breakpoint(break-1) {
    border-top: 1px solid rgba($color-alpha, .15);
    border-bottom: 1px solid rgba($color-alpha, .15);
  }
}

.module-contact .contact__infos .infos__info {
  display: flex;
  flex-direction: column;
  margin-bottom:0px;

  &:last-child {
    @include ms-margin-bottom(zero);
  }

  @include breakpoint(break-2) {
    flex-direction: row;
  }
}

.module-contact .contact__infos .info__label {
  @include font-weight-body(bold);
  width: 100%;

  @include breakpoint(break-1) {
    width: 30%;
  }
}

.module-contact .contact__infos .info__value {

}

.module-contact .contact__link {
  @include ms-margin-top(3);
}

.module-contact .contact__person {
  box-shadow: $shadow-alpha;
}

.module-contact .person__card {
  background-color: $white;
}

.module-contact .person__image img {
  width: 100%;
}

.module-contact .person__content {
  @include ms-padding(0 3 2);
}
