.module-card-detail {
  display: block;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-color: $white;
  box-shadow: $shadow-alpha;
  z-index: 15;

  @include breakpoint(break-2) {
    margin-top: -330px;
    max-width: 400px;
  }

}

.module-card-detail .card-detail__headline {
  @include ms-padding(2);
  border-bottom: 1px solid $color-grey-medium;
}

.module-card-detail .card-detail__image {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
  min-height: 180px;
  @include ms-padding(2);
  border-bottom: 1px solid $color-grey-medium;
  max-height: 100%;

  & img {
    display: block;
    width: auto;
    max-height: 100%;
    margin: 0 auto;
  }

}

.module-card-detail .card-detail__techdata {
  @include ms-padding(2);
  @include breakpoint(break-1) {
    text-align: center;
  }
  @include breakpoint(break-2) {
    text-align: left;
  }
}

.module-card-detail .card-detail__techdata table {
  @include ms-font-size(-1);

  & td {
    padding: 3px 0;
    vertical-align: top;


    &.col-0 {
      @include font-weight-body(bold);
      @include breakpoint(break-2) {
        width:60%;
      }
    }
  }
}
