// --------------------------------------------------------------------------
// Modules / pricelist
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles

// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-pricelist {
  opacity: 0;
  overflow: visible;
  transition: opacity .5s $easing-alpha-out;

  &.swiper-container-horizontal,
  &.-no-swiper {
    opacity: 1;
  }
  & a {
    text-decoration: none;
  }
}

html .module-pricelist.swiper-container {
  padding-bottom: gutter();
}

.module-pricelist .pricelists__pricelist {
  opacity: 1;
  position: relative;
  overflow: hidden;
  z-index: 2;
  box-shadow: $shadow-alpha;
}

.module-pricelist .pricelists__pricelist .pricelist__content {
  background-color: $white;
}

.module-pricelist .content__headline {
  min-height:110px;
  border-bottom: 1px solid $color-grey-light;
  display: flex;
  align-items: center;
  width: 100%;
  @include ms-padding(1 3);
  & span {
    color: $color-alpha;
    @include font-weight-body(bold);
    @include ms-font-size(1);
  }
}

.module-pricelist .content__text {
  @include ms-padding(1 3);
  border-bottom: 1px solid $color-grey-light;
}

.module-pricelist .content__text .text__price {
  color: $color-text-alpha;
  display: flex;
  flex-direction: column;
  margin-bottom:0px;

  &:last-child {
    @include ms-margin-bottom(zero);
  }

  @include breakpoint(break-2) {
    flex-direction: row;
  }

  .price__label {
    @include font-weight-body(bold);
    width: 100%;

    @include breakpoint(break-1) {
      width: 60%;
    }
  }

  .price__value {

  }
}

.module-pricelist .pricelists__pricelist .pricelist__button span {
  display: block;
  position: relative;
  @include font-weight-body(bold);
  @include ms-padding(2 3);
  background-color: $white;

  &:after {
    position: absolute;
    @include ms-right(3);
    top: 50%;
    transform: translateY(-50%);
  }
}

.module-pricelist .swiper-pagination {
  margin-bottom: -60px;

}
