// --------------------------------------------------------------------------
// Modules / Keyfacts
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-keyfacts {
  @include ms-padding(1 zero);
  @include ms-padding(2 zero, break-1 to break-4);

  @include breakpoint(break-1) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @include breakpoint(break-2) {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
}

.module-keyfacts .keyfacts__keyfact {
  position: relative;
  width: 100%;
  padding: .5em 0 .5em 52px;

  @include breakpoint(break-1) {
    width: 47%;
  }

  @include breakpoint(break-2) {
    width: 100%;
    margin-right: gutter();
    padding: 0 0 0 52px;
  }

  &:before {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: calc(50% - 21px);
    @include ms-font-size(4);
    color: $color-alpha;
  }

  &:last-child {
    margin-right: 0;
  }
}

.module-keyfacts .keyfact__wrapper {
  @include ms-padding-left(1);
}

.module-keyfacts .keyfact__title {
  line-height: 1;

  & span {
    color: $color-text-beta;
    @include font-weight-body(bold);
  }
}

.module-keyfacts .keyfact__desc {
  line-height: 1;

  & span {
    @include ms-font-size(-1);
  }
}
