.module-tags {
  .tags__tag {
    display: inline-block;
    color: $color-alpha;
    margin: 3px;
    padding: 2px 10px;
    border-radius: 3px;
    border: 1px solid $color-alpha;
    @include ms-font-size(-1);
    & a {
      text-decoration: none;

    }
  }
  .tags__tag--active {
    display: inline-block;
    background-color: $color-alpha;
    color: $white;
    margin: 3px;
    padding: 2px 10px;
    border-radius: 3px;
    border: 1px solid $color-alpha;

    & a {
      color: $white;

    }
  }
}
