// --------------------------------------------------------------------------
// Base / Forms
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. General Setup
// 02. Input Types
// 03. Button


// --------------------------------------------------------------------------
// 01. General Setup
// --------------------------------------------------------------------------

fieldset {
  border: 1px solid $color-grey-medium;
  border-radius: $border-radius;
  margin: 0;
}

input,
label,
select {
  display: block;
}

label {

  &.required:after {
    content: '*';
  }

  abbr {
    display: none;
  }
}


// --------------------------------------------------------------------------
// 02. Input Types
// --------------------------------------------------------------------------

textarea,
#{$all-text-inputs},
select {
  @include ms-padding(0 2);
  @include ms-margin-top(-1);
  background-color: $white;
  border: 1px solid $color-grey-medium;
  border-radius: 0;
  box-shadow: inset 0 1px 3px hsla(0, 0%, 0%, .06);
  width: 100%;

  &:hover {
    border-color: darken($color-grey-medium, 20%);
  }

  &:focus {
    border-color: $color-alpha;
    box-shadow: $color-grey-medium, 0 0 5px rgba($color-grey-dark, .7);
    outline: none;
  }

  &:last-child {
    @include ms-margin-bottom(zero);
  }
}

textarea {
  resize: vertical;
}

input[type="search"] {
  @include appearance(none);
}

input[type="checkbox"],
input[type="radio"] {
  display: inline;
}

input[type="file"] {
  width: 100%;
}

.select-style select {
  @include ms-padding(zero 2);
  padding-top:15px;
  padding-bottom:15px;

  @include ms-margin-top(-1);
  border: 1px solid $color-grey-medium;
  border-radius: 0;
  box-shadow: inset 0 1px 3px hsla(0, 0%, 0%, .06);
  -webkit-appearance: none;
}

.select-style select:focus {
  outline: none;
}

.checkbox-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;

  & label {
    width: calc(100% - 50px);
    margin-top: -7px;
    margin-left: 15px;
  }
}


input[type="submit"] {
  background-color: $color-alpha;
  border: 0;
  border-radius: $border-radius;
  color: $white;
  cursor: pointer;
  display: inline-block;
  line-height: $line-height;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
