// --------------------------------------------------------------------------
// Modules / Horizontal Card
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-hcard .cards__card {
  display: block;
  @include ms-margin-bottom(1);
  background-color: $white;
  box-shadow: $shadow-alpha;

  &:last-child {
    margin-bottom: 0;
  }
}

.module-hcard .card__twocol {
  @include breakpoint(break-1) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  & .twocol__left {
    position: relative;
    overflow: hidden;

    @include breakpoint(break-1) {
      width: 25%;
      transition: width .35s $easing-alpha-out;
    }
  }

  & .twocol__right {
    @include ms-padding(0 2, break-0);
    @include ms-padding(3 zero, break-1 to break-4);

    @include breakpoint(break-1) {
      width: 65%;
      margin-right: 5%;
      transition: margin .35s $easing-alpha-out;
    }
  }
}

.module-hcard .cards__card:hover .card__twocol {
  & .twocol__left {
    @include breakpoint(break-1) {
      width: 20%;
    }
  }

  & .twocol__right {
    @include breakpoint(break-1) {
      margin-right: 10%;
    }
  }
}

.module-hcard .card__image {
  width: 100%;
  height: 20vh;

  @include breakpoint(break-1) {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    pointer-events: none;
    z-index: 5;
  }
}

.module-hcard .card__image .module-image-preloaded {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.module-hcard .card__title {
  min-height: 2.8em;

  & h5 {
    color: $color-text-beta;
  }
}

.module-hcard .card__text {
  position: relative;

  &:after {
    display: block;
    position: absolute;
    right: 0;
    bottom: .3em;
    transition: right .35s $easing-alpha-out;
  }

  & p {
    color: $color-text-alpha;
    padding-right: 20px;
  }
}

.module-hcard .cards__card:hover .card__text:after {
  @include breakpoint(break-1) {
    right: -5%;
  }
}
