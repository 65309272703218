// --------------------------------------------------------------------------
// Modules / CTA Section
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-cta-slider .swiper-container {
  width: 100%;
  height: auto;
  overflow: visible;
}

.module-cta-slider .swiper-slide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 275px;
  background-color: $white;
  box-shadow: $shadow-alpha;
}

.module-cta-slider .swiper-container-horizontal > .swiper-scrollbar-wrapper {
  @include breakpoint(break-2) {
    width: calc(100% + 7vw);
  }

  @include breakpoint(break-3) {
    width: calc(100% + 9vw);
  }

  @include breakpoint(break-4) {
    width: 100%;
  }

  & .swiper-scrollbar {
    width: 70%;
    left: 50%;
    transform: translateX(-50%);
  }
}

.module-cta-slider .cta__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.module-cta-slider .col-one {
  position: relative;
  width: 100%;
  @include ms-margin(zero zero 3);
  @include ms-padding-right(zero, break-0 to break-1);
  @include ms-padding-right(3, break-2 to break-3);
  @include ms-padding-right(5, break-4);
  z-index: 2;

  @include breakpoint(break-2) {
    width: span(5);
    margin: 0 gutter() 0 0;
  }

  @include breakpoint(break-4) {
    width: span(4);
  }

  &:before {
    @include breakpoint(break-2) {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      top: 0;
      background-color: rgba($color-grey-light, .85);
      box-shadow: 0 0 30px 30px rgba($color-grey-light, .85);
    }
  }

  &:after {
    @include breakpoint(break-2) {
      content: '';
      display: block;
      position: absolute;
      width: 100vw;
      height: 100%;
      right: 0;
      top: 0;
      background-color: rgba($color-grey-light, .6);
      box-shadow: 0 0 30px 30px rgba($color-grey-light, .6);
    }
  }
}

.module-cta-slider .col-two {
  width: 100%;
  z-index: 1;
  @include ms-padding-top(4);

  @include breakpoint(break-2) {
    width: span(7);
  }

  @include breakpoint(break-4) {
    width: span(8);
  }
}

.module-cta-slider .col-one .cta__headline {
  position: relative;
  @include ms-margin-bottom(1);
  z-index: 2;
}

.module-cta-slider .col-one .cta__content {
  position: relative;
  z-index: 2;
}

.module-cta-slider .col-one .cta__link {
  position: relative;
  z-index: 2;
  @include ms-margin-top(3, break-0 to break-1);
  @include ms-margin-top(6, break-2 to break-4);
}

.module-cta-slider .col-two .slide__image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 200px;
  overflow: hidden;

  & img {
    width: 100%;
    height: 100%;
    max-height: 100%;
  }
}

.module-cta-slider .col-two .slide__image .image__label {
  position: absolute;
  @include ms-top(1);
  @include ms-right(1);
  padding: 0 6px;
  background-color: $color-alpha;
  border-radius: $border-radius;

  & span {
    display: block;
    @include ms-font-size(-1);
    color: $white;
  }
}

.module-cta-slider .col-two .slide__content {
  @include ms-margin(1 2);
}

.module-cta-slider .col-two .content__headline {
  @include ms-margin-bottom(1);

  & h5 {
    color: $color-alpha;
  }
}

.module-cta-slider .col-two .content__model-data .data__line {
  display: flex;
  justify-content: space-between;

  & .line__label,
  & .line__value {
    width: span(6);
  }
}

.module-cta-slider .col-two .content__model-data .line__label span {
  @include ms-font-size(-1);
  @include font-weight-body(bold);
}

.module-cta-slider .col-two .content__model-data .line__value span {
  @include ms-font-size(-1);
}

.module-cta-slider .slide__price {
  border-top: 1px solid rgba($color-text-alpha, .15);
  display: flex;
  justify-content: space-between;
  @include ms-padding(0 2);

  & .price__label,
  & .price__value {
    width: span(6);
  }

  & .price__label span {
    @include ms-font-size(-1);
    @include font-weight-body(bold);
  }

  & .price__value {
    text-align: right;

    & span {
      color: $color-alpha;
      @include font-weight-body(bold);
    }
  }
}
