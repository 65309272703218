// --------------------------------------------------------------------------
// Fonts / Icomoon
// --------------------------------------------------------------------------

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon/icomoon.eot?vrvobj');
  src: url('../fonts/icomoon/icomoon.eot?vrvobj#iefix') format('embedded-opentype'),
  url('../fonts/icomoon/icomoon.woff?vrvobj') format('woff'),
  url('../fonts/icomoon/icomoon.ttf?vrvobj') format('truetype'),
  url('../fonts/icomoon/icomoon.svg?vrvobj#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
  &:after, &:before {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.icon-arrow-up:before {
  content: "\e900";
}

.icon-chevron-right:after {
  content: "\e901";
}

.icon-golf-ball:before {
  content: "\e902";
}

.icon-checkmark:before {
  content: "\ea10";
}
