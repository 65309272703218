// --------------------------------------------------------------------------
// Modules / Tab
// --------------------------------------------------------------------------

// TABLE OF CONTENTS
// -----------------
// 01. Main Styles


// --------------------------------------------------------------------------
// 01. Main Styles
// --------------------------------------------------------------------------

.module-tab .tab__nav {
  display: none;

  @include breakpoint(break-2) {
    display: flex;
  }
}

.module-tab .tab__nav .nav__item {
  @include ms-margin-right(4);

  &:last-child {
    margin-right: 0;
  }

  & span {
    display: block;
    position: relative;
    cursor: pointer;
    transition: color .35s $easing-alpha-out;

    &:after {
      content: '';
      display: block;
      opacity: 0;
      position: absolute;
      width: 100%;
      height: 4px;
      left: 0;
      top: 160%;
      background-color: $color-text-beta;
      transform: translateY(10px);
      transition: opacity .35s $easing-alpha-out, transform .35s $easing-alpha-out;
    }
  }

  &.-active span {
    color: $color-text-beta;

    &:after {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.module-tab .tab__tabs .tabs__tab {
  margin-bottom: 2px;
  @include ms-margin-top(6, break-2 to break-4);

  @include breakpoint(break-2) {
    display: none;
  }

  &.-active {
    @include breakpoint(break-2) {
      display: block;
      animation: .5s $easing-alpha-out 0s 1 forwards tab-show;
    }
  }
}

@keyframes tab-show {
  0% { opacity: 0; transform: translateY(-10px); }
  100% { opacity: 1; transform: translateY(0); }
}

.module-tab .tab__tabs .tab__title {
  position: relative;
  @include ms-padding(2 4 2 2);
  cursor: pointer;
  border-radius: $border-radius;
  background-color: $color-alpha;
  transition: background .35s $easing-alpha-out;

  @include breakpoint(break-2) {
    display: none;
  }

  &:hover {
    background-color: darken($color-alpha, 5);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 5px;
    height: 9px;
    @include ms-right(2);
    top: 50%;
    margin-top: -4.5px;
    background: transparent url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAASCAMAAABVab95AAAAG1BMVEX///////////////////////////////////8HBRbfAAAACHRSTlMAInqLweDi/UsdkzwAAAAvSURBVHgBRca5AcAwEIAw8rP/xGnsQ5V4PFl0brfb7Xb71PoyO2g0Go3GN+O6935YowNc0buq8wAAAABJRU5ErkJggg==') no-repeat 50% 50% / contain;
    transition: transform .35s $easing-alpha-out;
  }

  &.-active-m {
    border-radius: $border-radius $border-radius 0 0;

    &:after {
      transform: rotate(90deg);
    }
  }

  & span {
    color: $white;
  }
}

.module-tab .tab__tabs .col-wrapper {
  max-height: 0;
  @include ms-padding(zero 2, break-0 to break-1);
  @include ms-padding(zero, break-2 to break-4);
  overflow: hidden;
  border-radius: 0 0 $border-radius $border-radius;
  background-color: $color-grey-light;
  box-shadow: inset 0 15px 15px -10px rgba($black, .2);

  @include breakpoint(break-2) {
    max-height: none;
    background-color: transparent;
    box-shadow: none;
  }
}

.module-tab .tab__tabs .tabs__tab.-active-m .col-wrapper {
  max-height: none;
  @include ms-padding(2, break-0 to break-1);
  @include ms-padding(zero, break-2 to break-4);
}

.module-tab .tab__tabs .content__text p {
  @include ms-margin-bottom(2);
}

.module-tab .tab__tabs .content__button {
  @include ms-margin-top(4);
}
